import { gql } from "apollo-boost";

const RESET_PASSWORD = gql`
  mutation($input: NewPasswordWithCodeInput) {
    updateForgottenPassword(input: $input) {
      status
      message
    }
  }
`;
export { RESET_PASSWORD };
