import React, { lazy, Suspense, useState } from "react";
import { Grid } from "@material-ui/core";
import flymyaWhiteLogo from "../assets/images/flymya-logo-white.svg";
import fblogo from "../assets/images/social-media/facebook.svg";
import instalogo from "../assets/images/social-media/instagram.svg";
import { Link } from "react-router-dom";
import linkedinlogo from "../assets/images/social-media/linkedin.svg";
import { getDataContentfulId } from "../utils/contentfulUtil";
import { retry } from "utils/retryloadable";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import VisibilitySensor from "react-visibility-sensor";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import hotelPartnerLogo from "../assets/images/hotel/Hotel_icon.webp";
import hotelPartnerLogoMobile from "../assets/images/hotel/Hotel_icons_mobile.webp";
import DoubleArrowIcon from "../assets/images/doubleArrowsIcon.svg";
import MediaQuery from "react-responsive";
import { isAuthenticated } from "lib/auth";
import { CALL_CENTER_PHONENO, CC_PHONENO_LINK } from "../config/constants";

// import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";

const LoadableAirlinePartner = lazy(() =>
  retry(() => import("pages/LoadableAirlinePartner"))
);
const LoadableFeaturedIn = lazy(() =>
  retry(() => import("pages/LoadableFeaturedIn"))
);

const Footer = ({
  productType,
  imageContent,
  pageContent,
  selectedLanguage,
  pageName
}) => {
  const [paymentImgSrc, setPaymentImgSrc] = useState("");
  const [showBusOperators, setShowBusOperators] = useState(false);
  // const PAYMENTS_IMG_URL =
  //   "https://images.ctfassets.net/7rpik7r2pwv0/1MkKCyjCiATGFKwoDj0NHv/99ec5d3b0c404ad5c5822028329a9c23/Payment_icons.png";
  const PAYMENTS_IMG_URL =
    "https://images.ctfassets.net/7rpik7r2pwv0/1MkKCyjCiATGFKwoDj0NHv/a88c50b950f541f7bf718c6e543c7b2f/Payment_icons.png";
  const loadPaymentImage = () => {
    const imgLoader = new Image();
    imgLoader.src = PAYMENTS_IMG_URL;
    imgLoader.onload = () => setPaymentImgSrc(PAYMENTS_IMG_URL);
  };

  return (
    <Grid container className="bgHomeService">
      <Suspense>
        {productType === "flight" && (
          <LoadableAirlinePartner
            productType={productType}
            pageContent={pageContent}
          />
        )}

        {pageName !== "ContactUs" && productType !== "car" && (
          <Grid item xs={12} sm={12} className="divAirlinePartner">
            {productType === "balloons" && (
              <div className="container">
                <span
                  className="titleSlider airlinePartner"
                  data-contentful-id={getDataContentfulId("partnersLabels")}
                >
                  {pageContent &&
                  pageContent.partnersLabels &&
                  pageContent.partnersLabels[2]
                    ? pageContent.partnersLabels[2]
                    : "Balloon Partners"}
                </span>
                <div className="bgWhite bgBalloonOperators">
                  <div
                    className="div-balloon-partner "
                    data-contentful-id={getDataContentfulId("stt-balloon")}
                  >
                    <img
                      src={
                        imageContent &&
                        imageContent["stt-balloon"] &&
                        imageContent["stt-balloon"].url
                      }
                      alt="Balloon Operator"
                    />
                  </div>
                  <div
                    className="div-balloon-eagle-partner"
                    data-contentful-id={getDataContentfulId("golden-eagle")}
                  >
                    <img
                      src={
                        imageContent &&
                        imageContent["golden-eagle"] &&
                        imageContent["golden-eagle"].url
                      }
                      alt="Balloon Operator"
                    />
                  </div>
                  <div
                    className="div-balloon-partner"
                    data-contentful-id={getDataContentfulId("oriental-balloon")}
                  >
                    <img
                      src={
                        imageContent &&
                        imageContent["oriental-balloon"] &&
                        imageContent["oriental-balloon"].url
                      }
                      alt="Balloon Operator"
                    />
                  </div>
                  <div
                    className="div-balloon-bob-partner"
                    data-contentful-id={getDataContentfulId(
                      "balloon-over-bagan"
                    )}
                  >
                    <img
                      src={
                        imageContent &&
                        imageContent["balloon-over-bagan"] &&
                        imageContent["balloon-over-bagan"].url
                      }
                      alt="Balloon Operator"
                    />
                  </div>
                </div>
              </div>
            )}

            {productType === "hotel" && (
              <div className="container">
                <span
                  style={{ paddingBottom: "20px" }}
                  className="titleSlider airlinePartner"
                >
                  {"Hotel Partners"}
                </span>
                <MediaQuery minWidth={780}>
                  <div className="" style={{ margin: "35px" }}>
                    <div className="imgContainer">
                      <img src={hotelPartnerLogo} alt="Hotel Partners Logo" />
                    </div>
                  </div>
                </MediaQuery>

                <MediaQuery maxWidth={780}>
                  <div
                    className=""
                    style={{
                      padding: "10px",
                      marginBottom: "40px"
                    }}
                  >
                    <div className="imgContainer">
                      <img
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                        src={hotelPartnerLogoMobile}
                        alt="Hotel Partners Logo"
                      />
                    </div>
                  </div>
                </MediaQuery>
              </div>
            )}

            {productType === "bus" && (
              <div className="container">
                <span
                  style={{ paddingBottom: "20px" }}
                  className="titleSlider airlinePartner"
                  data-contentful-id={getDataContentfulId("partnersLabels")}
                >
                  {pageContent &&
                  pageContent.partnersLabels &&
                  pageContent.partnersLabels[1]
                    ? pageContent.partnersLabels[1]
                    : "Bus Operators"}
                </span>
                <div className="bgWhite busOperatorLogo">
                  <div
                    className="imgContainer"
                    data-contentful-id={getDataContentfulId(
                      "busline-bagan-min-thar"
                    )}
                  >
                    <img
                      src={
                        imageContent &&
                        imageContent["busline-bagan-min-thar"] &&
                        imageContent["busline-bagan-min-thar"].url
                      }
                      alt="Bus Operator Logo"
                    />
                  </div>
                  <div
                    className="imgContainer"
                    data-contentful-id={getDataContentfulId(
                      "busline-shwe-mandalar"
                    )}
                  >
                    <img
                      src={
                        imageContent &&
                        imageContent["busline-shwe-mandalar"] &&
                        imageContent["busline-shwe-mandalar"].url
                      }
                      alt="Bus Operator Logo"
                    />
                  </div>
                  <div
                    className="imgContainer"
                    data-contentful-id={getDataContentfulId(
                      "busline-mandalar-min"
                    )}
                  >
                    <img
                      src={
                        imageContent &&
                        imageContent["busline-mandalar-min"] &&
                        imageContent["busline-mandalar-min"].url
                      }
                      alt="Bus Operator Logo"
                    />
                  </div>
                  <div
                    className="imgContainer"
                    data-contentful-id={getDataContentfulId(
                      "busline-myat-mandalar-tun"
                    )}
                  >
                    <img
                      src={
                        imageContent &&
                        imageContent["busline-myat-mandalar-tun"] &&
                        imageContent["busline-myat-mandalar-tun"].url
                      }
                      alt="Bus Operator Logo"
                    />
                  </div>
                  <div
                    className="imgContainer"
                    data-contentful-id={getDataContentfulId("busline-lumbani")}
                  >
                    <img
                      src={
                        imageContent &&
                        imageContent["busline-lumbani"] &&
                        imageContent["busline-lumbani"].url
                      }
                      alt="Bus Operator Logo"
                    />
                  </div>
                  <div
                    className="imgContainer"
                    data-contentful-id={getDataContentfulId("busline-gi-group")}
                  >
                    <img
                      src={
                        imageContent &&
                        imageContent["busline-gi-group"] &&
                        imageContent["busline-gi-group"].url
                      }
                      alt="Bus Operator Logo"
                    />
                  </div>
                  <div
                    className="imgContainer"
                    data-contentful-id={getDataContentfulId(
                      "busline-shwe-mandalay"
                    )}
                  >
                    <img
                      src={
                        imageContent &&
                        imageContent["busline-shwe-mandalay"] &&
                        imageContent["busline-shwe-mandalay"].url
                      }
                      alt="Bus Operator Logo"
                    />
                  </div>
                  <div
                    className="imgContainer"
                    data-contentful-id={getDataContentfulId(
                      "busline-shwe-lashio"
                    )}
                  >
                    <img
                      src={
                        imageContent &&
                        imageContent["busline-shwe-lashio"] &&
                        imageContent["busline-shwe-lashio"].url
                      }
                      alt="Bus Operator Logo"
                    />
                  </div>
                </div>
                <div className="bgWhite busOperatorLogo">
                  <div
                    className="imgContainer"
                    data-contentful-id={getDataContentfulId(
                      "busline-shwe-taung-yoe"
                    )}
                  >
                    <img
                      src={
                        imageContent &&
                        imageContent["busline-shwe-taung-yoe"] &&
                        imageContent["busline-shwe-taung-yoe"].url
                      }
                      alt="Bus Operator Logo"
                    />
                  </div>
                  <div
                    className="imgContainer"
                    data-contentful-id={getDataContentfulId(
                      "busline-khaing-mandalay"
                    )}
                  >
                    <img
                      src={
                        imageContent &&
                        imageContent["busline-khaing-mandalay"] &&
                        imageContent["busline-khaing-mandalay"].url
                      }
                      alt="Bus Operator Logo"
                    />
                  </div>
                  <div
                    className="imgContainer"
                    data-contentful-id={getDataContentfulId(
                      "busline-shwe-sin-sakyar"
                    )}
                  >
                    <img
                      src={
                        imageContent &&
                        imageContent["busline-shwe-sin-sakyar"] &&
                        imageContent["busline-shwe-sin-sakyar"].url
                      }
                      alt="Bus Operator Logo"
                    />
                  </div>
                  <div
                    className="imgContainer"
                    data-contentful-id={getDataContentfulId(
                      "busline-myo-sat-thit"
                    )}
                  >
                    <img
                      src={
                        imageContent &&
                        imageContent["busline-myo-sat-thit"] &&
                        imageContent["busline-myo-sat-thit"].url
                      }
                      alt="Bus Operator Logo"
                    />
                  </div>
                  <div
                    className="imgContainer"
                    data-contentful-id={getDataContentfulId(
                      "busline-man-yar-zar"
                    )}
                  >
                    <img
                      src={
                        imageContent &&
                        imageContent["busline-man-yar-zar"] &&
                        imageContent["busline-man-yar-zar"].url
                      }
                      alt="Bus Operator Logo"
                    />
                  </div>
                  <div
                    className="imgContainer"
                    data-contentful-id={getDataContentfulId(
                      "busline-high-class"
                    )}
                  >
                    <img
                      src={
                        imageContent &&
                        imageContent["busline-high-class"] &&
                        imageContent["busline-high-class"].url
                      }
                      alt="Bus Operator Logo"
                    />
                  </div>
                  <div
                    className="imgContainer"
                    data-contentful-id={getDataContentfulId("busline-tat-lan")}
                  >
                    <img
                      src={
                        imageContent &&
                        imageContent["busline-tat-lan"] &&
                        imageContent["busline-tat-lan"].url
                      }
                      alt="Bus Operator Logo"
                    />
                  </div>
                  <div
                    className="imgContainer"
                    data-contentful-id={getDataContentfulId(
                      "busline-taung-pyar-tan"
                    )}
                  >
                    <img
                      src={
                        imageContent &&
                        imageContent["busline-taung-pyar-tan"] &&
                        imageContent["busline-taung-pyar-tan"].url
                      }
                      alt="Bus Operator Logo"
                    />
                  </div>
                </div>
                {showBusOperators ? (
                  <>
                    <div className="bgWhite busOperatorLogo">
                      <div
                        className="imgContainer"
                        data-contentful-id={getDataContentfulId("busline-atw")}
                      >
                        <img
                          src={
                            imageContent &&
                            imageContent["busline-atw"] &&
                            imageContent["busline-atw"].url
                          }
                          alt="Bus Operator Logo"
                        />
                      </div>
                      <div
                        className="imgContainer"
                        data-contentful-id={getDataContentfulId(
                          "busline-chin-taung-tan"
                        )}
                      >
                        <img
                          src={
                            imageContent &&
                            imageContent["busline-chin-taung-tan"] &&
                            imageContent["busline-chin-taung-tan"].url
                          }
                          alt="Bus Operator Logo"
                        />
                      </div>
                      <div
                        className="imgContainer"
                        data-contentful-id={getDataContentfulId(
                          "busline-kyan-tai-aung"
                        )}
                      >
                        <img
                          src={
                            imageContent &&
                            imageContent["busline-kyan-tai-aung"] &&
                            imageContent["busline-kyan-tai-aung"].url
                          }
                          alt="Bus Operator Logo"
                        />
                      </div>
                      <div
                        className="imgContainer"
                        data-contentful-id={getDataContentfulId(
                          "busline-myat-yadanar"
                        )}
                      >
                        <img
                          src={
                            imageContent &&
                            imageContent["busline-myat-yadanar"] &&
                            imageContent["busline-myat-yadanar"].url
                          }
                          alt="Bus Operator Logo"
                        />
                      </div>
                      <div
                        className="imgContainer"
                        data-contentful-id={getDataContentfulId(
                          "busline-mya-yadanar"
                        )}
                      >
                        <img
                          src={
                            imageContent &&
                            imageContent["busline-mya-yadanar"] &&
                            imageContent["busline-mya-yadanar"].url
                          }
                          alt="Bus Operator Logo"
                        />
                      </div>
                      <div
                        className="imgContainer"
                        data-contentful-id={getDataContentfulId(
                          "busline-nan-htike-taw-win"
                        )}
                      >
                        <img
                          src={
                            imageContent &&
                            imageContent["busline-nan-htike-taw-win"] &&
                            imageContent["busline-nan-htike-taw-win"].url
                          }
                          alt="Bus Operator Logo"
                        />
                      </div>
                      <div
                        className="imgContainer"
                        data-contentful-id={getDataContentfulId(
                          "busline-new-mandalar-tun"
                        )}
                      >
                        <img
                          src={
                            imageContent &&
                            imageContent["busline-new-mandalar-tun"] &&
                            imageContent["busline-new-mandalar-tun"].url
                          }
                          alt="Bus Operator Logo"
                        />
                      </div>
                      <div
                        className="imgContainer"
                        data-contentful-id={getDataContentfulId(
                          "busline-ngwe-hnin-mandalar"
                        )}
                      >
                        <img
                          src={
                            imageContent &&
                            imageContent["busline-ngwe-hnin-mandalar"] &&
                            imageContent["busline-ngwe-hnin-mandalar"].url
                          }
                          alt="Bus Operator Logo"
                        />
                      </div>
                    </div>
                    <div className="bgWhite busOperatorLogo">
                      <div
                        className="imgContainer"
                        data-contentful-id={getDataContentfulId(
                          "busline-pan-taw-oo"
                        )}
                      >
                        <img
                          src={
                            imageContent &&
                            imageContent["busline-pan-taw-oo"] &&
                            imageContent["busline-pan-taw-oo"].url
                          }
                          alt="Bus Operator Logo"
                        />
                      </div>
                      <div
                        className="imgContainer"
                        data-contentful-id={getDataContentfulId(
                          "busline-shwe-kachin"
                        )}
                      >
                        <img
                          src={
                            imageContent &&
                            imageContent["busline-shwe-kachin"] &&
                            imageContent["busline-shwe-kachin"].url
                          }
                          alt="Bus Operator Logo"
                        />
                      </div>
                      <div
                        className="imgContainer"
                        data-contentful-id={getDataContentfulId(
                          "busline-shwe-kha-yee"
                        )}
                      >
                        <img
                          src={
                            imageContent &&
                            imageContent["busline-shwe-kha-yee"] &&
                            imageContent["busline-shwe-kha-yee"].url
                          }
                          alt="Bus Operator Logo"
                        />
                      </div>
                      <div
                        className="imgContainer"
                        data-contentful-id={getDataContentfulId(
                          "busline-shwe-lun-pyan"
                        )}
                      >
                        <img
                          src={
                            imageContent &&
                            imageContent["busline-shwe-lun-pyan"] &&
                            imageContent["busline-shwe-lun-pyan"].url
                          }
                          alt="Bus Operator Logo"
                        />
                      </div>
                      <div
                        className="imgContainer"
                        data-contentful-id={getDataContentfulId(
                          "busline-shwe-myanmar"
                        )}
                      >
                        <img
                          src={
                            imageContent &&
                            imageContent["busline-shwe-myanmar"] &&
                            imageContent["busline-shwe-myanmar"].url
                          }
                          alt="Bus Operator Logo"
                        />
                      </div>
                      <div
                        className="imgContainer"
                        data-contentful-id={getDataContentfulId(
                          "busline-shwe-myoe-taw"
                        )}
                      >
                        <img
                          src={
                            imageContent &&
                            imageContent["busline-shwe-myoe-taw"] &&
                            imageContent["busline-shwe-myoe-taw"].url
                          }
                          alt="Bus Operator Logo"
                        />
                      </div>
                      <div
                        className="imgContainer"
                        data-contentful-id={getDataContentfulId(
                          "busline-shwe-wal-thu"
                        )}
                      >
                        <img
                          src={
                            imageContent &&
                            imageContent["busline-shwe-wal-thu"] &&
                            imageContent["busline-shwe-wal-thu"].url
                          }
                          alt="Bus Operator Logo"
                        />
                      </div>
                      <div
                        className="imgContainer"
                        data-contentful-id={getDataContentfulId(
                          "busline-tat-nay-win"
                        )}
                      >
                        <img
                          src={
                            imageContent &&
                            imageContent["busline-tat-nay-win"] &&
                            imageContent["busline-tat-nay-win"].url
                          }
                          alt="Bus Operator Logo"
                        />
                      </div>
                    </div>
                    <div className="bgWhite busOperatorLogo">
                      <div
                        className="imgContainer"
                        data-contentful-id={getDataContentfulId(
                          "busline-tun-ayeyar"
                        )}
                      >
                        <img
                          src={
                            imageContent &&
                            imageContent["busline-tun-ayeyar"] &&
                            imageContent["busline-tun-ayeyar"].url
                          }
                          alt="Bus Operator Logo"
                        />
                      </div>
                      <div
                        className="imgContainer"
                        data-contentful-id={getDataContentfulId(
                          "busline-ye-aung-lan"
                        )}
                      >
                        <img
                          src={
                            imageContent &&
                            imageContent["busline-ye-aung-lan"] &&
                            imageContent["busline-ye-aung-lan"].url
                          }
                          alt="Bus Operator Logo"
                        />
                      </div>
                      <div
                        className="imgContainer"
                        data-contentful-id={getDataContentfulId(
                          "busline-yoe-ma-mandalar"
                        )}
                      >
                        <img
                          src={
                            imageContent &&
                            imageContent["busline-yoe-ma-mandalar"] &&
                            imageContent["busline-yoe-ma-mandalar"].url
                          }
                          alt="Bus Operator Logo"
                        />
                      </div>
                      <div
                        className="imgContainer"
                        data-contentful-id={getDataContentfulId("busline-zymg")}
                      >
                        <img
                          src={
                            imageContent &&
                            imageContent["busline-zymg"] &&
                            imageContent["busline-zymg"].url
                          }
                          alt="Bus Operator Logo"
                        />
                      </div>
                      <div
                        className="imgContainer"
                        data-contentful-id={getDataContentfulId("busline-boss")}
                      >
                        <img
                          src={
                            imageContent &&
                            imageContent["busline-boss"] &&
                            imageContent["busline-boss"].url
                          }
                          alt="Bus Operator Logo"
                        />
                      </div>
                      <div
                        className="imgContainer"
                        data-contentful-id={getDataContentfulId(
                          "busline-yar-zar-tun"
                        )}
                      >
                        <img
                          src={
                            imageContent &&
                            imageContent["busline-yar-zar-tun"] &&
                            imageContent["busline-yar-zar-tun"].url
                          }
                          alt="Bus Operator Logo"
                        />
                      </div>
                      <div
                        className="imgContainer"
                        data-contentful-id={getDataContentfulId(
                          "busline-ayein-na-mar"
                        )}
                      >
                        <img
                          src={
                            imageContent &&
                            imageContent["busline-ayein-na-mar"] &&
                            imageContent["busline-ayein-na-mar"].url
                          }
                          alt="Bus Operator Logo"
                        />
                      </div>
                      <div
                        className="imgContainer"
                        data-contentful-id={getDataContentfulId(
                          "busline-taung-paw-thar"
                        )}
                      >
                        <img
                          src={
                            imageContent &&
                            imageContent["busline-taung-paw-thar"] &&
                            imageContent["busline-taung-paw-thar"].url
                          }
                          alt="Bus Operator Logo"
                        />
                      </div>
                    </div>
                    <div className="bgWhite busOperatorLogo">
                      <div
                        className="imgContainer"
                        data-contentful-id={getDataContentfulId(
                          "busline-moe-htet-arkar"
                        )}
                      >
                        <img
                          src={
                            imageContent &&
                            imageContent["busline-moe-htet-arkar"] &&
                            imageContent["busline-moe-htet-arkar"].url
                          }
                          alt="Bus Operator Logo"
                        />
                      </div>
                      <div
                        className="imgContainer"
                        data-contentful-id={getDataContentfulId(
                          "busline-ma-naw-ta-kun"
                        )}
                      >
                        <img
                          src={
                            imageContent &&
                            imageContent["busline-ma-naw-ta-kun"] &&
                            imageContent["busline-ma-naw-ta-kun"].url
                          }
                          alt="Bus Operator Logo"
                        />
                      </div>
                      <div
                        className="imgContainer"
                        data-contentful-id={getDataContentfulId(
                          "busline-kyaw-hlwar"
                        )}
                      >
                        <img
                          src={
                            imageContent &&
                            imageContent["busline-kyaw-hlwar"] &&
                            imageContent["busline-kyaw-hlwar"].url
                          }
                          alt="Bus Operator Logo"
                        />
                      </div>
                      <div
                        className="imgContainer"
                        data-contentful-id={getDataContentfulId("busline-ok")}
                      >
                        <img
                          src={
                            imageContent &&
                            imageContent["busline-ok"] &&
                            imageContent["busline-ok"].url
                          }
                          alt="Bus Operator Logo"
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {!showBusOperators ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <div
                      style={{ color: "#00b7f0" }}
                      onClick={prev => {
                        setShowBusOperators(!showBusOperators);
                      }}
                    >
                      See all operators
                    </div>
                    <div>
                      <ExpandMoreIcon style={{ color: "#00b7f0" }} />
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <div
                      style={{ color: "#00b7f0" }}
                      onClick={prev => {
                        setShowBusOperators(!showBusOperators);
                      }}
                    >
                      See Less
                    </div>
                    <div>
                      <ExpandLessIcon style={{ color: "#00b7f0" }} />
                    </div>
                  </div>
                )}
              </div>
            )}
          </Grid>
        )}

        {pageName !== "ContactUs" && (
          <LoadableFeaturedIn pageContent={pageContent} />
        )}

        <Grid item xs={12} sm={12} className="footer">
          <div className="container">
            <Grid container>
              <Grid item xs={6} sm={4} md={2} className="grid-footer">
                <Grid item className="footer-bottom">
                  <span
                    className="titleSlider"
                    data-contentful-id={getDataContentfulId(
                      "contactaboutcareers"
                    )}
                  >
                    {pageContent &&
                    pageContent.contactaboutcareers &&
                    pageContent.contactaboutcareers[0]
                      ? pageContent.contactaboutcareers[0]
                      : "About Flymya"}
                  </span>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <Link
                            id="flymyaContactUs"
                            to={{
                              pathname: `/${
                                selectedLanguage ? selectedLanguage : "en"
                              }/contact-us`,
                              search: window.location.search
                            }}
                            data-contentful-id={getDataContentfulId(
                              "contactaboutcareers"
                            )}
                          >
                            {pageContent &&
                            pageContent.contactaboutcareers &&
                            pageContent.contactaboutcareers[1]
                              ? pageContent.contactaboutcareers[1]
                              : "Contact Us"}
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            href="https://careers.flymya.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            id="home-footer-careers"
                            data-contentful-id={getDataContentfulId(
                              "contactaboutcareers"
                            )}
                          >
                            {pageContent &&
                            pageContent.contactaboutcareers &&
                            pageContent.contactaboutcareers[2]
                              ? pageContent.contactaboutcareers[2]
                              : "Careers"}
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Link
                            id="flymyaAboutUs"
                            to={{
                              pathname: `/${
                                selectedLanguage ? selectedLanguage : "en"
                              }/about-us`,
                              search: window.location.search
                            }}
                            data-contentful-id={getDataContentfulId(
                              "contactaboutcareers"
                            )}
                          >
                            {pageContent &&
                            pageContent.contactaboutcareers &&
                            pageContent.contactaboutcareers[3]
                              ? pageContent.contactaboutcareers[3]
                              : "About Us"}
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Link
                            to={{
                              pathname: `/${
                                selectedLanguage ? selectedLanguage : "en"
                              }/privacy-policy`,
                              search: window.location.search
                            }}
                            target="_blank"
                            id="home-footer-privacypolicy"
                            data-contentful-id={getDataContentfulId(
                              "contactaboutcareers"
                            )}
                          >
                            {pageContent &&
                            pageContent.contactaboutcareers &&
                            pageContent.contactaboutcareers[4]
                              ? pageContent.contactaboutcareers[4]
                              : "Privacy Policy"}
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Grid>
              </Grid>
              <Grid item xs={6} sm={4} md={2} className="grid-footer">
                <Grid item className="footer-bottom">
                  <span
                    className="titleSlider"
                    data-contentful-id={getDataContentfulId("ourServices")}
                  >
                    {pageContent && pageContent.ourServices
                      ? pageContent.ourServices
                      : "Our services"}
                  </span>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <Link
                            id="ourServFlights"
                            to={{
                              pathname: `/${
                                selectedLanguage ? selectedLanguage : "en"
                              }`,
                              search: window.location.search
                            }}
                            data-contentful-id={getDataContentfulId("flight")}
                          >
                            {pageContent && pageContent.flight
                              ? pageContent.flight
                              : "Flights"}
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Link
                            id="ourServBus"
                            to={{
                              pathname: `/${
                                selectedLanguage ? selectedLanguage : "en"
                              }/bus`,
                              search: window.location.search
                            }}
                            data-contentful-id={getDataContentfulId("bus")}
                          >
                            {pageContent && pageContent.bus
                              ? pageContent.bus
                              : "Buses"}
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Link
                            id="ourServCar"
                            to={{
                              pathname: `/${
                                selectedLanguage ? selectedLanguage : "en"
                              }/car-rental`,
                              search: window.location.search
                            }}
                            data-contentful-id={getDataContentfulId("bus")}
                          >
                            Car Rentals
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Link
                            id="ourServHotels"
                            to={{
                              pathname: `/${
                                selectedLanguage ? selectedLanguage : "en"
                              }/hotel`,
                              search: window.location.search
                            }}
                            data-contentful-id={getDataContentfulId("hotel")}
                          >
                            {pageContent && pageContent.hotel
                              ? pageContent.hotel
                              : "Hotels"}
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Link
                            id="ourServBal"
                            to={{
                              pathname: `/${
                                selectedLanguage ? selectedLanguage : "en"
                              }/balloons`,
                              search: window.location.search
                            }}
                            data-contentful-id={getDataContentfulId("balloon")}
                          >
                            {pageContent && pageContent.balloon
                              ? pageContent.balloon
                              : "Balloons"}
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4} md={3} className="grid-footer">
                <Grid item className="footer-bottom">
                  <span
                    className="titleSlider"
                    data-contentful-id={getDataContentfulId("partnersLabels")}
                  >
                    {pageContent &&
                    pageContent.partnersLabels &&
                    pageContent.partnersLabels[3]
                      ? pageContent.partnersLabels[3]
                      : "Payment Partners"}
                  </span>
                  {productType !== "bus" && (
                    <VisibilitySensor
                      onChange={isVisible => isVisible && loadPaymentImage()}
                    >
                      <div>
                        <div className="row">
                          <div className="col">
                            <div
                              style={{
                                backgroundImage: `url('${paymentImgSrc}')`
                              }}
                              className="payment-img-visa"
                              data-contentful-id={getDataContentfulId(
                                "payment-img-visa"
                              )}
                            ></div>
                          </div>
                          <div className="col">
                            <div
                              style={{
                                backgroundImage: `url('${paymentImgSrc}')`
                              }}
                              className="payment-img-mobile-banking"
                              data-contentful-id={getDataContentfulId(
                                "payment-img-mobile-banking"
                              )}
                            ></div>
                          </div>
                          <div className="col">
                            <div
                              style={{
                                backgroundImage: `url('${paymentImgSrc}')`
                              }}
                              className="payment-img-mpu"
                              data-contentful-id={getDataContentfulId(
                                "payment-img-mpu"
                              )}
                            ></div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div
                              style={{
                                backgroundImage: `url('${paymentImgSrc}')`
                              }}
                              className="payment-img-123"
                              data-contentful-id={getDataContentfulId(
                                "payment-img-123"
                              )}
                            ></div>
                          </div>
                          <div className="col">
                            <div
                              style={{
                                backgroundImage: `url('${paymentImgSrc}')`
                              }}
                              className="payment-img-kbz-quickpay"
                              data-contentful-id={getDataContentfulId(
                                "payment-img-kbz-quickpay"
                              )}
                            ></div>
                          </div>
                          <div className="col">
                            <div
                              style={{
                                backgroundImage: `url('${paymentImgSrc}')`
                              }}
                              className="payment-img-cod"
                              data-contentful-id={getDataContentfulId(
                                "payment-img-cod"
                              )}
                            ></div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div
                              style={{
                                backgroundImage: `url('${paymentImgSrc}')`
                              }}
                              className="payment-img-wave-pay"
                              data-contentful-id={getDataContentfulId(
                                "payment-img-wave-pay"
                              )}
                            ></div>
                          </div>
                          <div className="col">
                            <div
                              style={{
                                backgroundImage: `url('${paymentImgSrc}')`
                              }}
                              className="payment-img-kbz-pay"
                              data-contentful-id={getDataContentfulId(
                                "payment-img-kbz-pay"
                              )}
                            ></div>
                          </div>
                          <div className="col">
                            <div
                              style={{
                                backgroundImage: `url('${paymentImgSrc}')`
                              }}
                              className="payment-img-one-pay"
                              data-contentful-id={getDataContentfulId(
                                "payment-img-one-pay"
                              )}
                            ></div>
                          </div>
                        </div>
                        {/* <div className="row">
                          <div className="col">
                            <div
                              style={{
                                backgroundImage: `url('${paymentImgSrc}')`
                              }}
                              className="payment-img-mpitesan"
                              data-contentful-id={getDataContentfulId(
                                "payment-img-mpitesan"
                              )}
                            ></div>
                          </div>
                          <div className="col">
                            <div
                              style={{
                                backgroundImage: `url('${paymentImgSrc}')`
                              }}
                              className="payment-img-citizen-pay "
                              data-contentful-id={getDataContentfulId(
                                "payment-img-citizenpay"
                              )}
                            ></div>
                          </div>
                        </div> */}
                      </div>
                    </VisibilitySensor>
                  )}

                  {productType === "bus" && (
                    <div>
                      <div className="row">
                        <div className="col">
                          <div className="payment-img-visa"></div>
                        </div>
                        <div className="col">
                          <div className="payment-img-mpu"></div>
                        </div>
                        <div className="col">
                          <div className="payment-img-wave-pay"></div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="payment-img-kbz-pay"></div>
                        </div>
                      </div>
                    </div>
                  )}
                </Grid>
              </Grid>
              <Grid className="grid-footer" item xs={12} sm={4} md={2}>
                {!isAuthenticated() && (
                  <div>
                    <span className="titleSlider">Become a Partner</span>

                    <a
                      href="https://www.notion.so/94ca309c4dd04c5ab80de0d9abe2f712"
                      target="_blank"
                    >
                      <button className="becomePartnerButton">
                        Find Out More <img src={DoubleArrowIcon} alt="Icon" />
                      </button>
                    </a>
                  </div>
                )}
              </Grid>
              {/* <Grid
                item
                xs={12}
                sm={4}
                md={2}
                className="grid-footer flymyaFooterLogo"
              >
                <div>
                  <br />
                  <br />
                  <div>
                    <div className="allday-img"></div>
                    <div>
                      <span
                        data-contentful-id={getDataContentfulId(
                          "contactaboutcareers"
                        )}
                      >
                        {pageContent &&
                        pageContent.contactaboutcareers &&
                        pageContent.contactaboutcareers[1]
                          ? pageContent.contactaboutcareers[1]
                          : "Contact Us"}
                      </span>
                      <span className="footerPhoneNo">
                        <a href="tel:+959880441011">+95 9 880 441 011</a>
                      </span>
                    </div>
                  </div>
                </div>
              </Grid> */}
            </Grid>
            <Grid item md={12} xs={12} sm={12}>
              <Grid container className="footer-app">
                <Grid item md={2} xs={12} sm={4} className="grid-footer">
                  <span className="titleSlider">Social Media</span>
                  <div className="footer-social-media">
                    <a
                      href="https://www.facebook.com/flymyatravels/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        data-src={fblogo}
                        alt="social media icon"
                        className="lazyload"
                        width="42"
                        height="42"
                      />
                    </a>
                    <a
                      href="https://www.instagram.com/flymya/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        data-src={instalogo}
                        className="lazyload"
                        alt="social media icon"
                        width="42"
                        height="42"
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/flymyatravels"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        data-src={linkedinlogo}
                        className="lazyload"
                        alt="social media icon"
                        width="42"
                        height="42"
                      />
                    </a>
                  </div>
                </Grid>
                <Grid item md={2} xs={12} sm={4} className="grid-footer">
                  <span
                    style={{ display: "inline-flex" }}
                    data-contentful-id={getDataContentfulId("website-ads")}
                  >
                    <img
                      data-src={
                        imageContent &&
                        imageContent["website-ads"] &&
                        imageContent["website-ads"].url
                      }
                      alt="Website Ads"
                      width="230"
                      height="124"
                      className="lazyload"
                    />
                  </span>
                </Grid>
                <Grid
                  item
                  md={3}
                  xs={12}
                  sm={4}
                  className="grid-footer appDownload"
                >
                  <a
                    href="https://play.google.com/store/apps/details?id=com.flymya&hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                    data-contentful-id={getDataContentfulId("google-play")}
                  >
                    <img
                      id="downloadGooglePlay"
                      data-src={
                        imageContent &&
                        imageContent["google-play"] &&
                        imageContent["google-play"].url
                      }
                      alt="Google Play Store"
                      className="lazyload"
                      width="150"
                      height="45"
                    />
                  </a>
                  <a
                    href="https://apps.apple.com/us/app/flymya/id1241868739"
                    target="_blank"
                    rel="noopener noreferrer"
                    data-contentful-id={getDataContentfulId("app-store")}
                  >
                    <img
                      id="downloadAppStore"
                      data-src={
                        imageContent &&
                        imageContent["app-store"] &&
                        imageContent["app-store"].url
                      }
                      alt="App Store"
                      className="lazyload"
                      width="150"
                      height="45"
                    />
                  </a>
                  <a
                    href="https://appgallery.huawei.com/#/app/C102800565"
                    target="_blank"
                    rel="noopener noreferrer"
                    data-contentful-id={getDataContentfulId("huawei-ag-icon")}
                  >
                    <img
                      id="downloadHuaweiAG"
                      data-src={
                        imageContent &&
                        imageContent["huawei-ag-icon"] &&
                        imageContent["huawei-ag-icon"].url
                      }
                      alt="Huawei App Gallery"
                      className="lazyload"
                      width="150"
                      height="45"
                    />
                  </a>
                </Grid>

                {/* Contact Us Logo Grid */}
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={2}
                  className="grid-footer flymyaFooterLogo"
                >
                  <div className="contactUSLogoFooter">
                    <br />
                    <br />
                    <div>
                      <div className="allday-img"></div>
                      <div>
                        <span
                          data-contentful-id={getDataContentfulId(
                            "contactaboutcareers"
                          )}
                        >
                          {pageContent &&
                          pageContent.contactaboutcareers &&
                          pageContent.contactaboutcareers[1]
                            ? pageContent.contactaboutcareers[1]
                            : "Contact Us"}
                        </span>
                        <span className="footerPhoneNo">
                          <a href={CC_PHONENO_LINK}>{CALL_CENTER_PHONENO}</a>
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>

                {/* <Grid
                  item
                  md={2}
                  xs={12}
                  sm={4}
                  className="appDownload grid-footer"
                ></Grid> */}
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} className="footer-gridbox">
              Copyright Ⓒ 2022 Flymya Tech Co.,Ltd.
            </Grid>
          </div>
        </Grid>
      </Suspense>
    </Grid>
  );
};

export default Footer;
