export const IMG_BASE_URL = "https://flymya.com";
export const DATALAYER_URL = "https://booking.flymya.com";
export const REACT_APP_GORGIAS_TICKET =
  "https://v3-be.flymya.com/api/gorgias/post/tickets/";
export const REACT_APP_REDIRECT_URL = window.location.origin;

export const CALL_CENTER_PHONENO = "+95 9 880 441 011";
export const CC_PHONENO_LINK = "tel:+959880441011";

export const CONTENTFUL_API =
  "https://cdn.contentful.com/spaces/7rpik7r2pwv0/entries?access_token=R8A8mF9iZO5I1-nHcwuPNRrlHN0xP83_32eNnt1_0QM";

export const MM_LOCALE = "my-MM";
export const EN_LOCALE = "en-US";
export const MY_MM = "my-mm";

export const BUS_TIMEOUT = 7;

export const TWO_MINS_TIMEOUT = 120000;
export const TEN_MINS_TIMEOUT = 600000;

export const TOP_CITY_BUS = [
  "Yangon",
  "Mandalay",
  "Naypyitaw (Myoma)",
  "Naypyitaw (Bawga)",
  "Naypyitaw (Thapyaygone)",
  "Naypyitaw(Toll Gate)"
];

export const AIR_KBZ_AIRLINE = "Air KBZ";
export const MNA_AIRLINE = "Myanmar National Airlines";
export const GMA_AIRLINE = "Golden Myanmar Airlines";
export const MYP_AIRLINE = "Mann Yadanarpon Airlines";
export const ATL_AIRLINE = "Air Thanlwin";
export const MAI_AIRLINE = "Myanmar Airways International";

export const TOKEN_TYPE = "token_type";
export const AUTH_TOKEN = "access_token";
export const LOGIN_USER_ID = "userId";
export const IDENTIFIER = "identifier";
export const EXPIRES_IN = "expires_in";
export const LOGIN_PEOPLE_COMPANY = "login_company";
export const LOGIN_PEOPLE_CLASS_TYPE = "login_company_class_type";
export const LOGIN_USER_PEOPLE = "login_people";
export const IS_B2B = "is_b2b";
export const IS_CALL_CENTER = "is_call_center";
export const IS_CUSTOMER = "is_customer";
export const IS_EMPLOYEE = "is_employee";
export const LOGIN_PEOPLE_DETAIL = "login_people_detail";
export const B2B_ClASS_TYPE = "b2bClassType";

export const B2BCLASSTYPE_B_ID = "1";
export const B2BCLASSTYPE_N_ID = "2";
export const B2C_ID = "3";
export const B2BCLASSTYPE_C_ID = "4";

export const DOMESTIC_PRODUCT_TYPE_ID = 1;
export const DOMESTIC_ROUNDTRIP_PRODUCT_TYPE_ID = 12;
export const HOTEL_PRODUCT_TYPE_ID = 4;

export const SALES_WAVEPAY_APP = 19;
export const SALES_ONEPAY_APP = 18;
export const SALES_KBZPAY_APP = 17;
export const SALES_MPITESAN_APP = 22;
export const SALES_AYAPAY_APP = 44;
export const SALES_WEBSITE = 15;
export const SALES_DESKTOP = 23;
export const SALES_MOBILE = 24;
export const SALES_TABLET = 25;
export const SALES_B2B_PORTAL = 28;
export const SALES_B2B_OFFLINE_PORTAL = 39;

export const B2B_OFFLINE_PORTAL = 40;
export const B2B_CORPORATE_ONLINE = 41;
export const B2B_CORPORATE_OFFLINE = 42;
export const SALES_CITIZENPAY_APP = 43;

export const SOURCE_TYPE_CAMPAIGN = 1;
export const SOURCE_TYPE_REFERRAL = 2;
export const SOURCE_TYPE_PROMO = 3;
export const SOURCE_TYPE_DEVICE = 4;

export const ONLINE_PAYMENTS = [
  "KBZ Pay",
  "Wave Money",
  "Stripe",
  "MPU (2C2P)",
  "Paypal",
  "M-Pitesan",
  "123 OTC (2C2P)"
];

export const MYANMAR_ID = 153;
export const YANGON_REGION = 4;
export const YANGON_CITY = 28;
export const SINGAPORE_ID = 200;

export const MOBILE_REDIRECT = "OFF"; // Value 'ON' || 'OFF' to show or hide the mobile redirect area (HomePromote Component)
