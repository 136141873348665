import React from "react";
import Grid from "@material-ui/core/Grid";
import { MY_MM } from "config/constants";
import {
  ContentfulData,
  getDataContentfulId
} from "../../utils/contentfulUtil";

const FlightProgressbar = ({ pageName, selectedLanguage, pageContent }) => {
  return (
    <Grid item xs={9} sm={7} md={7} className="progressBarContainer">
      <ul
        className={
          selectedLanguage === MY_MM
            ? "progressbar mm-progress"
            : "progressbar eng-progress"
        }
      >
        <li
          className={
            pageName === "FlightSearch"
              ? "active"
              : "" + pageName !== "/"
              ? "completed"
              : ""
          }
        >
          <span
            className="step-info"
            data-contentful-id={getDataContentfulId("book")}
          >
            <ContentfulData content={pageContent} id="book">
              Book
            </ContentfulData>
          </span>
        </li>
        <li
          className={
            pageName === "CustomerDetails"
              ? "active"
              : "" + pageName === "FlightPayment" ||
                pageName === "FlightConfirmation"
              ? "completed"
              : ""
          }
        >
          <span
            className="step-info"
            data-contentful-id={getDataContentfulId("info")}
          >
            <ContentfulData content={pageContent} id="info">
              Info
            </ContentfulData>
          </span>
        </li>
        <li
          className={
            pageName === "FlightPayment"
              ? "active"
              : "" + pageName === "FlightConfirmation"
              ? "completed"
              : ""
          }
        >
          <span
            className="step-info"
            data-contentful-id={getDataContentfulId("pay")}
          >
            <ContentfulData content={pageContent} id="pay">
              Pay
            </ContentfulData>
          </span>
        </li>
        <li className={pageName === "FlightConfirmation" ? "active" : ""}>
          <span
            className="step-info"
            data-contentful-id={getDataContentfulId("confirm")}
          >
            <ContentfulData content={pageContent} id="confirm">
              Confirm
            </ContentfulData>
          </span>
        </li>
      </ul>
    </Grid>
  );
};
export default FlightProgressbar;
