import React, { Component, useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import axios from "axios";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/react-hooks";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { clientV3 } from "utils/v3utils";
import { getClientTag } from "utils/checkurl";
import { pick } from "lodash";
import queryString from "query-string";

/** Fonts */
import "@fontsource/roboto";
import "@fontsource/poppins";
import "@fontsource/lato";

Bugsnag.start({
  logger: null,
  enabledReleaseStages: ["production"],
  releaseStage: process.env.REACT_APP_TRACK_RELEASE_STAGE,
  apiKey: process.env.REACT_APP_TRACK_TOKEN,
  plugins: [new BugsnagPluginReact()]
});
const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
// const httpLink = new HttpLink({
//   uri:
//     process.env.REACT_APP_GQLBACKEND_SERVER +
//     process.env.REACT_APP_GRAPHQL_SERVER
// });

// const client = new ApolloClient({
//   link: httpLink,
//   cache: new InMemoryCache()
// });

// TrackJS.install({
//   token: process.env.REACT_APP_TRACK_TOKEN,
//   application: "flymyawebsite"
// });

axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    // Do something with response error
    Bugsnag.notify(error);
    return Promise.reject(error);
  }
);
if (window.location.pathname.includes("in-web")) {
  let script = document.createElement("script");
  script.setAttribute("src", "https://plausible.io/js/plausible.js");
  script.setAttribute("type", "text/javascript");
  script.setAttribute("data-domain", "flymya.com");
  document.head.appendChild(script);
}

class Index extends Component {
  render() {
    return (
      <ApolloProvider client={clientV3}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ApolloProvider>
    );
  }
}

ReactDOM.render(
  <ErrorBoundary>
    <Index />
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
