import { gql } from "apollo-boost";

const LOGIN_QUERY = gql`
  mutation($input: WebsiteLoginInput) {
    websiteLogin(input: $input) {
      refresh_token
      access_token
      expires_in
      token_type
      user {
        id
        name
        email
        company {
          id
          name
          active
          people {
            id
            prefix
            name
            middle_name
            last_name
            phone
            email
            nationality {
              id
              name
            }
            dob
            passport
            passport_exp
            nrc
            address
            township {
              id
              name
              city {
                id
                name
                region {
                  id
                  name
                }
              }
            }
          }
        }
        roles {
          id
          name
          permissions {
            id
            name
          }
        }
        people {
          id
          prefix
          name
          middle_name
          last_name
          phone
          email
          nationality {
            id
            name
          }
          dob
          passport
          passport_exp
          nrc
          address
          township {
            id
            name
            city {
              id
              name
              region {
                id
                name
              }
            }
          }
          company_people {
            id
            name
            class_type {
              id
            }
          }
        }
      }
    }
  }
`;

const LOGOUT_QUERY = gql`
  mutation logout {
    logout {
      status
      message
    }
  }
`;

const FIREBASE_LOGIN_QUERY = gql`
  mutation($input: WebsiteLoginInput) {
    firebaseLogin(input: $input) {
      refresh_token
      access_token
      expires_in
      token_type
      user {
        id
        name
        email
        company {
          id
          name
          people {
            id
            prefix
            name
            middle_name
            last_name
            phone
            email
            nationality {
              id
              name
            }
            dob
            passport
            passport_exp
            nrc
            address
            township {
              id
              name
              city {
                id
                name
                region {
                  id
                  name
                }
              }
            }
          }
        }
        roles {
          id
          name
          permissions {
            id
            name
          }
        }
        people {
          id
          prefix
          name
          middle_name
          last_name
          phone
          email
          nationality {
            id
            name
          }
          dob
          passport
          passport_exp
          nrc
          address
          township {
            id
            name
            city {
              id
              name
              region {
                id
                name
              }
            }
          }
          company_people {
            id
            name
          }
        }
      }
    }
  }
`;
const FIREBASE_SIGNUP_QUERY = gql`
  mutation firebaseSignup($input: RegisterInput) {
    firebaseSignup(input: $input) {
      tokens {
        access_token
        refresh_token
        expires_in
        token_type
        user {
          id
          name
          email
        }
      }
      status
    }
  }
`;

const FIREBASE_LOGIN_BY_TOKEN_QUERY = gql`
  mutation($input: FirebaseLoginInput) {
    firebaseLoginByToken(input: $input) {
      refresh_token
      access_token
      id_token
      expires_in
      token_type
      user {
        id
        name
        phone
        email
        roles {
          id
          name
          permissions {
            id
            name
          }
        }
        people {
          id
          sales
          prefix
          name
          last_name
          phone
          email

          dob
          passport
          passport_exp
          nrc

          nationality {
            id
            name
            iso_code_2
            iso_code_3
            currency_code
            nationality
            phone
          }
          company {
            id
            name
            internal_id
          }
          company_people {
            id
            name
            internal_id
          }
        }
      }
    }
  }
`;
const GET_COMPONENT_HAS_PERMISSION = gql`
  query($filter: [filterInput], $first: Int!, $page: Int) {
    componentHasPermission(
      filter: $filter
      first: $first
      page: $page
      orderBy: [{ field: "id", order: DESC }]
    ) {
      data {
        id
        permission {
          id
          name
          url
        }
        source {
          id
          name
        }
        classType {
          id
          name
        }
      }
      paginatorInfo {
        count
        currentPage
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

const CREATE_USER_PROFILE = gql`
  mutation($input: userProfileInput) {
    createUserProfile(input: $input) {
      id
      login {
        id
        email
        roles {
          id
          name
        }
        login_type {
          id
          name
        }
      }
      company {
        id
        name
      }
      company_people {
        id
        name
      }
      profiles {
        id
        main {
          id
          prefix
          name
        }
        profile
        people {
          id
          prefix
          name
        }
      }
      sales
      prefix
      name
      last_name
      middle_name
      phone
      email
      nationality {
        id
        name
      }
      dob
      passport
      passport_exp
      township {
        id
        name
        city_id
        city {
          id
          name
          region_id
          region {
            id
            name
            country {
              id
              name
              iso_code_2
              iso_code_3
              currency_code
              nationality
              phone
            }
          }
        }
      }
    }
  }
`;

const RESET_PASSWORD = gql`
  mutation($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
      status
      message
    }
  }
`;

const UPDATE_PASSWORD = gql`
  mutation($input: NewPasswordWithCodeInput) {
    updateForgottenPassword(input: $input) {
      status
      message
    }
  }
`;

export {
  LOGIN_QUERY,
  LOGOUT_QUERY,
  FIREBASE_LOGIN_QUERY,
  FIREBASE_SIGNUP_QUERY,
  FIREBASE_LOGIN_BY_TOKEN_QUERY,
  GET_COMPONENT_HAS_PERMISSION,
  CREATE_USER_PROFILE,
  RESET_PASSWORD,
  UPDATE_PASSWORD
};
