import React, { useState, useEffect, useRef } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Button,
  Typography,
  CircularProgress
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import clsx from "clsx";
import PageHeader from "pages/PageHeader";
import { clientV3 } from "../../utils/v3utils";
import { EN_LOCALE } from "../../config/constants";
import { RESET_PASSWORD } from "../../queries/resetpasswordquery";
import Footer from "pages/Footer";
import { ContentfulEntries } from "../../utils/Contentful";
import { useParams, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const ForgetPassword = props => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [pageContent, setPageContent] = useState({});
  const [imageContent, setImageContent] = useState([]);
  const [isSpinner, setIsSpinner] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [success, setSuccess] = useState(false);

  const classes = useStyles();
  const { token } = useParams();
  const { search } = useLocation();
  let searchParams = new URLSearchParams(search);

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      getContentfulEntries(EN_LOCALE);
      window.scrollTo(0, 0);
      mounted.current = true;
    } else {
      window.scrollTo(0, 0);
    }
  });

  const getContentfulEntries = language => {
    ContentfulEntries(
      "masterHomePage,masterHomeSecondPage,images,masterPassengerInformation,aboutUs",
      language
    )
      .then(response => {
        setPageContent(response.contentData);
        setImageContent(response.contentImages);
      })
      .catch(error => {
        console.log(error);
        setPageContent(error.contentData);
        setImageContent(error.contentImages);
      });
  };

  const handlePasswordTypeChange = () => {
    setShowPassword(!showPassword);
  };

  const handleResetPassword = async () => {
    setIsSpinner(true);
    if (password.length < 8) {
      setPasswordError(true);
      setIsSpinner(false);
      setPasswordErrorMessage("Must be at least 8 characters");
    } else if (password !== confirmPassword) {
      setPasswordError(true);
      setIsSpinner(false);
      setPasswordErrorMessage("Both passwords must match");
    } else {
      setPasswordError(false);
      setPasswordErrorMessage("");
      let resetData = {
        email: searchParams?.get("email"),
        token,
        password,
        password_confirmation: confirmPassword
      };
      try {
        const { data } = await clientV3.mutate({
          mutation: RESET_PASSWORD,
          variables: {
            input: resetData
          }
        });

        if (data?.updateForgottenPassword?.status === "PASSWORD_NOT_UPDATED") {
          setIsSpinner(false);
          setErrorMsg("This password reset token is invalid.");
        } else if (
          data?.updateForgottenPassword?.status === "PASSWORD_UPDATED"
        ) {
          setIsSpinner(false);
          setErrorMsg("");
          setSuccess(true);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Reset Password</title>
      </Helmet>
      <PageHeader
        pageName="ResetPassword"
        selectedLanguage={EN_LOCALE}
        pageContent={pageContent}
        pathName={props.location}
      />
      <div className={classes.forgetPasswordContainer}>
        {/* <Grid container> */}
        <div className={classes.forgetPassText}>
          <Typography component="h1" variant="h5">
            Create New Password
          </Typography>
        </div>
        {!success && (
          <>
            <div className={classes.text}>
              <Typography component="h2">
                {" "}
                Your new password must be different form previous used
                passwords.
              </Typography>
            </div>
            <div className={classes.formWrapper}>
              <div>
                <FormControl
                  className={clsx(classes.margin, classes.textField)}
                >
                  <InputLabel>Password</InputLabel>

                  <OutlinedInput
                    className={classes.passwordInput}
                    onChange={e => {
                      setPassword(e.target.value);
                      setPasswordError(false);
                      setPasswordErrorMessage("");
                    }}
                    type={showPassword ? "text" : "password"}
                    fullWidth={false}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: "-10px"
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={handlePasswordTypeChange}>
                          {showPassword ? (
                            <Visibility className={classes.iconColor} />
                          ) : (
                            <VisibilityOff className={classes.iconColor} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                </FormControl>
              </div>
              <div style={{ marginTop: "7px" }}>
                <FormControl
                  className={clsx(classes.margin, classes.textField)}
                >
                  <InputLabel>Confirm Password</InputLabel>

                  <OutlinedInput
                    className={classes.passwordInput}
                    onChange={e => {
                      setConfirmPassword(e.target.value);
                      setPasswordError(false);
                      setPasswordErrorMessage("");
                    }}
                    type="password"
                    fullWidth={false}
                    labelWidth={130}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: "-10px"
                    }}
                  />
                </FormControl>
              </div>
              {passwordError && passwordErrorMessage.length > 0 && (
                <small className={classes.errorText}>
                  {passwordErrorMessage}
                </small>
              )}
              {errorMsg !== "" && (
                <p className={classes.errorText}>{errorMsg}</p>
              )}
            </div>
            <div className={classes.btnMargin}>
              <Button
                onClick={handleResetPassword}
                variant="contained"
                className={classes.resetBtn}
                disabled={isSpinner}
              >
                Reset Password
                {isSpinner && <CircularProgress className={classes.loading} />}
              </Button>
            </div>
          </>
        )}

        {success && (
          <div className={classes.successText}>
            Your password has been reset successfully.
          </div>
        )}
        {/* </Grid> */}
      </div>
      <Footer
        pageName="ContactUs"
        pageContent={pageContent}
        imageContent={imageContent}
        selectedLanguage={EN_LOCALE}
      />
    </>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    forgetPasswordContainer: {
      display: "flex",
      flexDirection: "column",
      marginBottom: "30px"
      //alignItems: "center"
    },
    forgetPassText: {
      //fontSize: 25,
      fontWeight: "normal",
      marginTop: 50,
      marginBottom: 25,
      textAlign: "center"
    },
    formInputWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    margin: {
      margin: theme.spacing(1)
    },
    textField: {
      width: "40ch",
      [theme.breakpoints.down("md")]: {
        width: "38ch"
      },
      [theme.breakpoints.down("sm")]: {
        width: "30ch"
      },
      [theme.breakpoints.down("xs")]: {
        width: "30ch"
      }
    },
    passwordInput: {
      padding: "12px !important",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    iconColor: {
      color: "#000 !important"
    },
    text: {
      textAlign: "center",
      width: "100%",
      fontWeight: "initial",
      color: "#666666"
    },
    formWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "30px"
    },
    loading: {
      width: "20px !important",
      height: "20px !important",
      marginLeft: "20px"
    },
    btnMargin: {
      marginTop: theme.spacing(3),
      textAlign: "center",
      // marginLeft: "25px",

      width: "100%"
    },
    resetBtn: {
      backgroundColor: "#2f80ed !important",
      color: "#fff",
      textTransform: "capitalize",
      width: "30%",
      fontSize: "18px",
      marginLeft: "-10px",
      [theme.breakpoints.down("md")]: {
        width: "38ch"
      },
      [theme.breakpoints.down("sm")]: {
        width: "30ch"
      },
      [theme.breakpoints.down("xs")]: {
        width: "25ch"
      },
      "&.Mui-disabled": {
        color: "#fff"
      }
    },
    errorText: {
      color: "red"
    },
    successText: {
      color: "#00a651",
      margin: "0 auto",
      fontSize: "20px",
      fontWeight: "700",
      marginBottom: "40px",
      fontFamily: "Poppins"
    }
  })
);

export default ForgetPassword;
