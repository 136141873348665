import React, { lazy, Suspense } from "react";
import "App.css";
import "assets/scss/Home.scss";
import "assets/scss/HomeMobile.scss";
import "assets/scss/HotelCommon.scss";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  useHistory
} from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { retry } from "utils/retryloadable";
import ForgetPassword from "./pages/forgetpassword/ForgetPassword.js";

const MaintenancePage = lazy(() =>
  retry(() => import("pages/MaintenancePage"))
);

const HomePage = lazy(() => retry(() => import("pages/HomePage")));
const PrivacyPolicy = lazy(() => retry(() => import("pages/PrivacyPolicy")));
const PrivacyPolicyBoca = lazy(() =>
  retry(() => import("pages/PrivacyPolicyBoca"))
);
const ContactUs = lazy(() => retry(() => import("pages/ContactUs")));
const AboutUs = lazy(() => retry(() => import("pages/AboutUs")));
const Page404 = lazy(() => retry(() => import("pages/Page404")));
const PromotionsPage = lazy(() => retry(() => import("pages/Promotions")));
const B2BBalancePage = lazy(() =>
  retry(() => import("pages/loginusers/B2BBalancePage"))
);
const B2BTopup = lazy(() => retry(() => import("pages/loginusers/B2BTopup")));
const B2BTransfer = lazy(() =>
  retry(() => import("pages/loginusers/B2BTransfer"))
);
const BookingHistory = lazy(() =>
  retry(() => import("pages/loginusers/BookingHistory"))
);
const B2BPaymentPage = lazy(() => retry(() => import("pages/B2BPaymentPage")));
const FlightResult = lazy(() =>
  retry(() => import("pages/flight/FlightResult"))
);
const CustomerInfo = lazy(() =>
  retry(() => import("pages/flight/CustomerInfo"))
);
const FlightConfirmation = lazy(() =>
  retry(() => import("pages/flight/FlightConfirmation"))
);
const ShowResultMessage = lazy(() =>
  retry(() => import("pages/flight/ShowResultMessage"))
);
const PaymentPage = lazy(() => retry(() => import("pages/PaymentPage")));

const BalloonHome = lazy(() =>
  retry(() => import("pages/balloons/BalloonHome"))
);
const BalloonPaymentPage = lazy(() =>
  retry(() => import("pages/balloons/BalloonPaymentPage"))
);
const BalloonCustomerInfo = lazy(() =>
  retry(() => import("pages/balloons/BalloonCustomerInfo"))
);
const BalloonConfirmation = lazy(() =>
  retry(() => import("pages/balloons/BalloonConfirmation"))
);

const BusHome = lazy(() => retry(() => import("pages/buses/BusHome")));
const BusResult = lazy(() => retry(() => import("pages/buses/BusResult")));
const BusSeatList = lazy(() => retry(() => import("pages/buses/BusSeatList")));
const BusCustomerInfo = lazy(() =>
  retry(() => import("pages/buses/BusCustomerInfo"))
);
const BusConfirmation = lazy(() =>
  retry(() => import("./pages/buses/BusConfirmation.js"))
);
const BusTransactionFail = lazy(() =>
  retry(() => import("pages/buses/BusTransactionFail"))
);

const HotelHome = lazy(() => retry(() => import("pages/hotels/HotelHome")));

const HotelSearchResult = lazy(() =>
  retry(() => import("pages/hotels/HotelSearchResult"))
);
const HotelDetailPage = lazy(() =>
  retry(() => import("pages/hotels/HotelDetailPage"))
);

const HotelCustomerPage = lazy(() =>
  retry(() => import("pages/hotels/HotelCustomerPage"))
);

const HotelPaymentPage = lazy(() =>
  retry(() => import("pages/hotels/HotelPaymentPage"))
);

const HotelConfirmationPage = lazy(() =>
  retry(() => import("pages/hotels/HotelConfirmationPage"))
);

const CarHome = lazy(() => retry(() => import("pages/carrental/CarHome")));

const PromotionDetailPage = lazy(() =>
  retry(() => import("pages/PromotionDetail"))
);

const InsiderDashBoardPage = lazy(() =>
  retry(() => import("pages/loginusers/InsiderDashboard"))
);

const Banner = lazy(() => retry(() => import("components/CovidAlertBanner")));

const theme = createMuiTheme({
  typography: {
    fontFamily: "Roboto, sans-serif"
  }
});

function App() {
  const history = useHistory();
  history.push("/maintenance");

  return (
    <MuiThemeProvider theme={theme}>
      <Router>
        <Suspense fallback={<></>}>
          <Switch>
            <Route exact path="/maintenance" component={MaintenancePage} />

            <Route exact path="/:locale?" component={HomePage} />
            <Route exact path="/:locale/flight" component={HomePage} />
            <Route exact path="/:locale/error" component={ShowResultMessage} />

            <Route
              exact
              path="/:locale/flight/:flightType/:flightRoute/:departureDate/:returnDate/:nationality/:b2bClassType/:passengers"
              component={FlightResult}
            />
            <Route
              exact
              path="/:locale/flight/:flightType/:flightRoute/:departureDate/:returnDate/:nationality/:b2bClassType/:passengers/:departureUUID"
              component={FlightResult}
            />
            <Route
              exact
              path="/:locale/flight/:flightType/customer-details/:flightUUID"
              component={CustomerInfo}
            />
            <Route
              exact
              path="/:locale/flight/:flightType/customer-details/:flightUUID/:returnFlightUUID"
              component={CustomerInfo}
            />
            <Route
              exact
              path="/:locale/flight/payment-method/:bookingId"
              component={PaymentPage}
            />
            <Route
              exact
              path="/:locale/flight/payment-method/:bookingId/:returnBookingId"
              component={PaymentPage}
            />
            <Route
              exact
              path="/:locale/flight/confirmation/:bookingId"
              component={FlightConfirmation}
            />
            <Route
              exact
              path="/:locale/flight/confirmation/:bookingId/:returnBookingId"
              component={FlightConfirmation}
            />
            <Route
              exact
              path="/:locale/in-app/show-message"
              component={ShowResultMessage}
            />
            <Route
              exact
              path="/:locale/show-message"
              component={ShowResultMessage}
            />
            {/*Balloon url*/}
            <Route exact path="/:locale/balloons" component={BalloonHome} />
            <Route
              exact
              path="/:locale/balloons/customer-details/:cityId/:sightseeingDate/:passengers"
              component={BalloonCustomerInfo}
            />
            <Route
              exact
              path="/:locale/balloons/payment-method/:bookingId"
              component={BalloonPaymentPage}
            />
            <Route
              exact
              path="/:locale/balloons/confirmation/:bookingId"
              component={BalloonConfirmation}
            />
            {/*Balloon url*/}
            {/*Bus url*/}
            <Route exact path="/:locale/bus" component={BusHome} />
            <Route
              exact
              path="/:locale/bus/result/:departureCity/:arrivalCity/:departureDate/:nationality/:b2bClassType/:passengers"
              component={BusResult}
            />
            <Route
              exact
              path="/:locale/bus/select-seat/:busDetailId"
              component={BusSeatList}
            />
            <Route
              exact
              path="/:locale/bus/contact-info/:busDetailId"
              component={BusCustomerInfo}
            />
            <Route
              exact
              path="/:locale/bus/payment-method/:bookingId"
              component={PaymentPage}
            />
            <Route
              exact
              path="/:locale/bus/confirmation/:bookingId"
              component={BusConfirmation}
            />
            <Route
              exact
              path="/:locale/bus/transaction-fail"
              component={BusTransactionFail}
            />
            {/*Bus url*/}
            {/*Hotel url*/}
            <Route exact path="/:locale/hotel" component={HotelHome} />
            <Route
              exact
              path="/:locale/hotel/search/:currency/:nationality/:city/:checkIn/:checkOut/:adultChildRoom"
              component={HotelSearchResult}
            />
            <Route
              exact
              path="/:locale/hotel/choose/:currency/:nationality/:city/:stars/:checkIn/:checkOut/:adultChildRoom/:hotelId"
              component={HotelDetailPage}
            />
            <Route
              exact
              path="/:locale/hotel/customer/:currency/:nationality/:hotelId/:stars/:checkIn/:checkOut/:adult/:child/:blockIds/:blockCounts"
              component={HotelCustomerPage}
            />
            <Route
              exact
              path="/:locale/hotel/payment-method/:bookingId"
              component={PaymentPage}
            />
            <Route
              exact
              path="/:locale/hotel/payment"
              component={HotelPaymentPage}
            />
            <Route
              exact
              path="/:locale/hotel/confirmation/:reservationId"
              component={HotelConfirmationPage}
            />
            <Route
              exact
              path="/password/reset/:token"
              component={ForgetPassword}
            />
            {/*Hotel url*/}
            {/* in-app flight url */}
            <Route exact path="/in-app" component={HomePage} />
            <Route exact path="/:locale/in-app" component={HomePage} />
            <Route exact path="/:locale/in-app/flight" component={HomePage} />
            <Route
              exact
              path="/:locale/in-app/flight/:flightType/:flightRoute/:departureDate/:returnDate/:nationality/:b2bClassType/:passengers"
              component={FlightResult}
            />
            <Route
              exact
              path="/:locale/in-app/flight/:flightType/:flightRoute/:departureDate/:returnDate/:nationality/:b2bClassType/:passengers/:departureUUID"
              component={FlightResult}
            />
            <Route
              exact
              path="/:locale/in-app/flight/:flightType/customer-details/:flightUUID/"
              component={CustomerInfo}
            />
            <Route
              exact
              path="/:locale/in-app/flight/:flightType/customer-details/:flightUUID/:returnFlightUUID"
              component={CustomerInfo}
            />
            <Route
              exact
              path="/:locale/in-app/flight/confirmation/:bookingId"
              component={FlightConfirmation}
            />
            <Route
              exact
              path="/:locale/in-app/flight/confirmation/:bookingId/:returnBookingId"
              component={FlightConfirmation}
            />
            {/* in-app bus url */}
            <Route exact path="/:locale/in-app/bus" component={BusHome} />
            <Route
              exact
              path="/:locale/in-app/bus/result/:departureCity/:arrivalCity/:departureDate/:nationality/:b2bClassType/:passengers"
              component={BusResult}
            />
            <Route
              exact
              path="/:locale/in-app/bus/select-seat/:busDetailId"
              component={BusSeatList}
            />
            <Route
              exact
              path="/:locale/in-app/bus/contact-info/:busDetailId"
              component={BusCustomerInfo}
            />
            <Route
              exact
              path="/:locale/in-app/bus/payment-method/:bookingId"
              component={PaymentPage}
            />
            <Route
              exact
              path="/:locale/in-app/bus/confirmation/:bookingId"
              component={BusConfirmation}
            />
            <Route
              exact
              path="/:locale/in-app/bus/transaction-fail"
              component={BusTransactionFail}
            />
            {/* <Route
              exact
              path="/:locale/in-app/buses/result/:departureCity/:arrivalCity/:departureDate/:nationality/:passengers"
              component={BusResult}
            />
            <Route
              exact
              path="/:locale/in-app/buses/select-seat/:busDetailId"
              component={BusSeatList}
            />
            <Route
              exact
              path="/:locale/in-app/buses/contact-info/:busDetailId"
              component={BusCustomerInfo}
            />
            <Route
              exact
              path="/:locale/in-app/bus/confirmation/:bookingId"
              component={BusConfirmation}
            />
            <Route
              exact
              path="/:locale/in-app/buses/transaction-fail"
              component={BusTransactionFail} 
            />
            */}
            {/* in-app bus url*/}
            {/* in-app balloon url*/}
            <Route
              exact
              path="/:locale/in-app/balloons"
              component={BalloonHome}
            />
            <Route
              exact
              path="/:locale/in-app/balloons/customer-details/:cityId/:sightseeingDate/:passengers"
              component={BalloonCustomerInfo}
            />
            <Route
              exact
              path="/:locale/in-app/balloons/confirmation/:bookingId"
              component={BalloonConfirmation}
            />
            {/* in-app balloon url*/}
            {/* in-app hotel url */}
            <Route exact path="/:locale/in-app/hotel" component={HotelHome} />
            <Route
              exact
              path="/:locale/in-app/hotel/search/:currency/:nationality/:city/:checkIn/:checkOut/:adultChildRoom"
              component={HotelSearchResult}
            />
            <Route
              exact
              path="/:locale/in-app/hotel/choose/:currency/:nationality/:city/:stars/:checkIn/:checkOut/:adultChildRoom/:hotelId"
              component={HotelDetailPage}
            />
            <Route
              exact
              path="/:locale/in-app/hotel/customer/:currency/:nationality/:hotelId/:stars/:checkIn/:checkOut/:adult/:child/:blockIds/:blockCounts"
              component={HotelCustomerPage}
            />
            <Route
              exact
              path="/:locale/in-app/hotel/payment"
              component={HotelPaymentPage}
            />
            <Route
              exact
              path="/:locale/in-app/hotel/confirmation/:reservationId"
              component={HotelConfirmationPage}
            />
            {/* in-app hotel url */}

            {/* car rental url*/}
            <Route exact path="/:locale/car-rental" component={CarHome} />
            {/* car rental url*/}
            <Route
              exact
              path="/:locale/privacy-policy"
              component={PrivacyPolicy}
            />
            <Route
              exact
              path="/:locale/privacy-policy-boca"
              component={PrivacyPolicyBoca}
            />
            <Route exact path="/:locale/contact-us" component={ContactUs} />
            <Route exact path="/:locale/about-us" component={AboutUs} />
            <Route
              exact
              path="/:locale/balance/:b2buserId"
              component={B2BBalancePage}
            />

            <Route
              exact
              path="/:locale/booking-history/:b2buserId"
              component={BookingHistory}
            />
            <Route
              exact
              path="/:locale/in-app/booking-history/:b2buserId"
              component={BookingHistory}
            />
            <Route
              exact
              path="/:locale/insider-dashboard/:b2cuserPeopleId"
              component={InsiderDashBoardPage}
            />
            <Route
              exact
              path="/:locale/b2b-topup/:b2buserId/:currency/:type"
              component={B2BTopup}
            />
            <Route
              exact
              path="/:locale/b2b-transfer/:b2buserId/:currency/:type"
              component={B2BTransfer}
            />
            <Route
              exact
              path="/:locale/flight/b2b-payment-method/:bookingId"
              component={B2BPaymentPage}
            />
            <Route
              exact
              path="/:locale/flight/payment-method/:bookingId/:returnBookingId"
              component={PaymentPage}
            />
            <Route
              exact
              path="/:locale/in-app/promotion"
              component={PromotionsPage}
            />
            <Route
              exact
              path="/:locale/in-app/promotion/:title"
              component={PromotionDetailPage}
            />
            <Route exact path="/:locale/promotion" component={PromotionsPage} />
            <Route
              exact
              path="/:locale/promotion/:title"
              component={PromotionDetailPage}
            />
            {/* kbzpay inweb url*/}
            <Route exact path="/:locale/in-web" component={HomePage} />

            {/* in-web flight url */}
            <Route exact path="/in-web" component={HomePage} />
            <Route exact path="/:locale/in-web" component={HomePage} />
            <Route exact path="/:locale/in-web/flight" component={HomePage} />
            <Route
              exact
              path="/:locale/in-web/flight/:flightType/:flightRoute/:departureDate/:returnDate/:nationality/:b2bClassType/:passengers"
              component={FlightResult}
            />
            <Route
              exact
              path="/:locale/in-web/flight/:flightType/:flightRoute/:departureDate/:returnDate/:nationality/:b2bClassType/:passengers/:departureUUID"
              component={FlightResult}
            />
            <Route
              exact
              path="/:locale/in-web/flight/:flightType/customer-details/:flightUUID/"
              component={CustomerInfo}
            />
            <Route
              exact
              path="/:locale/in-web/flight/:flightType/customer-details/:flightUUID/:returnFlightUUID"
              component={CustomerInfo}
            />
            <Route
              exact
              path="/:locale/in-web/flight/payment-method/:bookingId"
              component={PaymentPage}
            />
            <Route
              exact
              path="/:locale/in-web/flight/payment-method/:bookingId/:returnBookingId"
              component={PaymentPage}
            />
            <Route
              exact
              path="/:locale/in-web/flight/confirmation/:bookingId"
              component={FlightConfirmation}
            />
            <Route
              exact
              path="/:locale/in-web/flight/confirmation/:bookingId/:returnBookingId"
              component={FlightConfirmation}
            />
            {/* in-web bus url */}
            <Route exact path="/:locale/in-web/bus" component={BusHome} />
            <Route
              exact
              path="/:locale/in-web/bus/result/:departureCity/:arrivalCity/:departureDate/:nationality/:b2bClassType/:passengers"
              component={BusResult}
            />
            <Route
              exact
              path="/:locale/in-web/bus/select-seat/:busDetailId"
              component={BusSeatList}
            />
            <Route
              exact
              path="/:locale/in-web/bus/contact-info/:busDetailId"
              component={BusCustomerInfo}
            />
            <Route
              exact
              path="/:locale/in-web/bus/payment-method/:bookingId"
              component={PaymentPage}
            />
            <Route
              exact
              path="/:locale/in-web/bus/confirmation/:bookingId"
              component={BusConfirmation}
            />
            <Route
              exact
              path="/:locale/in-web/bus/transaction-fail"
              component={BusTransactionFail}
            />
            {/* in-web bus url*/}

            {/* in-web carrental url*/}
            <Route
              exact
              path="/:locale/in-web/car-rental"
              component={CarHome}
            />
            {/* in-web carrental url*/}

            {/* in-web balloon url*/}
            <Route
              exact
              path="/:locale/in-web/balloons"
              component={BalloonHome}
            />
            <Route
              exact
              path="/:locale/in-web/balloons/customer-details/:cityId/:sightseeingDate/:passengers"
              component={BalloonCustomerInfo}
            />
            <Route
              exact
              path="/:locale/in-web/balloons/confirmation/:bookingId"
              component={BalloonConfirmation}
            />
            {/* in-web balloon url*/}

            {/* in-web hotel url */}
            <Route exact path="/:locale/in-web/hotel" component={HotelHome} />
            <Route
              exact
              path="/:locale/in-web/hotel/search/:currency/:nationality/:city/:checkIn/:checkOut/:adultChildRoom"
              component={HotelSearchResult}
            />
            <Route
              exact
              path="/:locale/in-web/hotel/choose/:currency/:nationality/:city/:stars/:checkIn/:checkOut/:adultChildRoom/:hotelId"
              component={HotelDetailPage}
            />
            <Route
              exact
              path="/:locale/in-web/hotel/customer/:currency/:nationality/:hotelId/:stars/:checkIn/:checkOut/:adult/:child/:blockIds/:blockCounts"
              component={HotelCustomerPage}
            />
            <Route
              exact
              path="/:locale/in-web/hotel/payment-method/:bookingId"
              component={PaymentPage}
            />
            <Route
              exact
              path="/:locale/in-web/hotel/confirmation/:reservationId"
              component={HotelConfirmationPage}
            />
            {/* in-web hotel url */}
            <Route
              exact
              path="/:locale/in-web/promotion"
              component={PromotionsPage}
            />
            <Route
              exact
              path="/:locale/in-web/promotion/:title"
              component={PromotionDetailPage}
            />

            {/* <Redirect to="/" /> */}
            <Route component={Page404} />
          </Switch>
        </Suspense>
      </Router>
    </MuiThemeProvider>
  );
}

export default App;
