import { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { clientV3 } from "utils/v3utils";

import { GET_COMPANY } from "queries/loginuserquery";
import { LOGIN_PEOPLE_COMPANY, LOGIN_USER_ID, IS_B2B } from "config/constants";
import { isInAppUrl } from "utils/checkurl";

function useResultPageB2BTopUp({
  props,
  isInApp,
  selectedLanguage,
  isForeginer = false
}) {
  const mounted = useRef();
  const location = useLocation();
  const history = useHistory();
  const [topUpCompanyAccountInfo, setTopUpCompanyAccountInfo] = useState({
    accountCode: "",
    currency: "",
    balance: ""
  });

  const isB2B = localStorage.getItem(IS_B2B) === "yes";

  /**
   * Function To handle Top-Up Button Click on Bus or Flight Result Page for B2B user
   */
  const resultPageTopUpBtnClick = () => {
    const topUpUrlPath = isInAppUrl(isInApp, selectedLanguage, "b2b-topup");
    const b2bCurrency = isForeginer ? "1" : "0";
    const userID = localStorage.getItem(LOGIN_USER_ID);
    let search = window.location.search;

    if (search !== "") {
      search += `&next=${location.pathname}${location.search}`;
    } else {
      search = `?next=${location.pathname}${location.search}`;
    }

    history.push({
      pathname: `${topUpUrlPath}/${userID}/${b2bCurrency}/1`,
      search
    });
  };

  /**
   * Function to remove next= search param from params
   * @returns {String}
   */
  const removeNextSearchParam = () => {
    const searchParams = new URLSearchParams(window.location.search);
    let search = "";
    for (let [key, value] of searchParams) {
      if (key === "next") continue;
      search === ""
        ? (search += `?${key}=${value}`)
        : (search += `&${key}=${value}`);
    }
    return search;
  };

  /**
   * Function to give redirect path after topup success
   * @returns {String}
   */
  const topUpSuccessRedirectPath = () => {
    const searchParams = new URLSearchParams(props.location.search);
    let path =
      isInAppUrl(false, selectedLanguage, "") +
      "balance/" +
      props.match.params.b2buserId;

    if (searchParams.get("next")) {
      path = searchParams.get("next").split("?")[0];
    }

    return path;
  };

  /**
   * Function to give search param for redirect after topup success
   * @returns {String}
   */
  const topUpSuccessRedirectSearchParam = () => {
    let search = removeNextSearchParam();
    const searchParams = new URLSearchParams(props.location.search);
    if (
      searchParams.get("next") &&
      searchParams.get("next").split("?").length > 1
    ) {
      search = "?" + searchParams.get("next").split("?")[1];
    }
    return search;
  };

  /**
   * Function to fetch B2B user company Data
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getCompanyDataApi = async () => {
    try {
      const res = await clientV3.query({
        query: GET_COMPANY,
        fetchPolicy: "network-only",
        variables: {
          filter: [
            {
              field: "id",
              operator: "=",
              value: JSON.parse(localStorage.getItem(LOGIN_PEOPLE_COMPANY))[0]
                .id
            }
          ],
          orderBy: [{ field: "id", order: "DESC" }],
          first: 1,
          page: 1
        }
      });
      if (
        res &&
        res.data &&
        res.data.company &&
        res.data.company.data.length > 0
      ) {
        const companyAccount = res.data.company.data[0].company_accounts;
        const matchCurrency = isForeginer ? "USD" : "MMK";
        const account = companyAccount.find(
          i =>
            i.account_function === "Customer Account" &&
            i.currency === matchCurrency
        );
        if (!account) return;
        setTopUpCompanyAccountInfo({
          accountCode: account.account_code || "",
          currency: account.currency || "",
          balance: account.balance || 0
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  // Use Effect Function to Fetch Api
  useEffect(() => {
    if (isB2B && !mounted.current) getCompanyDataApi();
    mounted.current = true;
  }, [getCompanyDataApi, isB2B]);

  return {
    topUpCompanyAccountInfo,
    resultPageTopUpBtnClick,
    topUpSuccessRedirectPath,
    topUpSuccessRedirectSearchParam,
    removeNextSearchParam
  };
}

export default useResultPageB2BTopUp;
