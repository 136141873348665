import { gql } from "apollo-boost";

const GET_COMPANY = gql`
  query(
    $first: Int!
    $page: Int
    $filter: [filterInput]
    $orderBy: [OrderByClause!]
  ) {
    company(orderBy: $orderBy, first: $first, page: $page, filter: $filter) {
      data {
        id
        name
        internal_id
        class_type {
          id
          name
        }
        company_accounts {
          id
          account_code
          company {
            id
            name
          }
          name
          account_function
          type
          balance
          currency
          credit_type {
            id
            name
          }
          max_amount
          date
          repeats
          repeat_num
        }
        people {
          id
          prefix
          name
          last_name
          phone
          email
        }
        aliases {
          id
          name
          domain
          sales
          supplier_account
          customer_account
        }
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

const GET_RESERVATIONS = gql`
  query(
    $filter: [filterInput]
    $first: Int!
    $page: Int
    $orderBy: [OrderByClause!]
  ) {
    reservationsByPeople(
      filter: $filter
      first: $first
      page: $page
      orderBy: $orderBy
    ) {
      data {
        id
        total {
          id
          show_fare_amount
          show_fare_currency
        }
        balance {
          id
          show_fare_amount
          show_fare_currency
        }
        currency
        created_at
        status {
          id
          name
        }
        products {
          id
          status {
            id
            name
          }
        }
        transactions {
          id
          status {
            id
            name
          }
        }
        reservation_people {
          id
          roles {
            id
            name
          }
        }
        created_by {
          id
          name
          email
        }
      }
      paginatorInfo {
        count
        currentPage
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

const GET_PRODUCT_BY_RESERVATION_ID = gql`
  query(
    $filter: [filterInput]
    $first: Int!
    $page: Int
    $orderBy: [OrderByClause!]
  ) {
    reservationsByPeople(
      filter: $filter
      first: $first
      page: $page
      orderBy: $orderBy
    ) {
      data {
        id
        products {
          id
          status {
            id
            name
          }
          product_type {
            id
            name
            order
          }
          sales_category {
            id
            name
          }
          external_id
          description
          travel_date
          date_of_sale
          issued_date
          supplier {
            id
            name
            company {
              id
              name
            }
          }
          operator {
            id
            code
            name
          }
          product_meta {
            id
            key
            value
            product {
              id
            }
            roundtrip
          }
          selling_price {
            id
            physical_amount
            physical_currency
          }
          gross_base {
            id
            physical_amount
            physical_currency
          }
          taxes {
            id
            physical_amount
            physical_currency
          }
          service_fees {
            id
            physical_amount
            physical_currency
          }
        }
      }
    }
  }
`;

const GET_TRANSACTION_BY_RESERVATION_ID = gql`
  query(
    $filter: [filterInput]
    $first: Int!
    $page: Int
    $orderBy: [OrderByClause!]
  ) {
    reservationsByPeople(
      filter: $filter
      first: $first
      page: $page
      orderBy: $orderBy
    ) {
      data {
        id
        transactions {
          id
          status {
            id
            name
          }
          external_id
          payment_date
          payer {
            id
            people {
              id
              prefix
              name
              last_name
            }
          }
          payment_method {
            id
            name
            currencies {
              code
              name
            }
          }
          company_account {
            id
            name
            currency
          }
          payment_method_active
          amount {
            id
            physical_amount
            physical_currency
          }
          remarks
        }
      }
    }
  }
`;

const GET_PASSENGER_BY_RESERVATION_ID = gql`
  query(
    $filter: [filterInput]
    $first: Int!
    $page: Int
    $orderBy: [OrderByClause!]
  ) {
    reservationsByPeople(
      filter: $filter
      first: $first
      page: $page
      orderBy: $orderBy
    ) {
      data {
        id
        reservation_people {
          id
          people {
            id
            prefix
            name
            last_name
            passport
          }
          roles {
            id
            name
          }
        }
      }
    }
  }
`;

const GET_TRANSACTIONS = gql`
  query(
    $filter: [filterInput]
    $first: Int!
    $page: Int
    $orderBy: [OrderByClause!]
  ) {
    transactionsByPeople(
      filter: $filter
      first: $first
      page: $page
      orderBy: $orderBy
    ) {
      data {
        id
        transaction_id
        reservation {
          id
        }
        transaction_type {
          id
          name
        }
        amount {
          id
          physical_amount
          physical_currency
        }
        payment_date
        status {
          id
          name
        }
      }
      paginatorInfo {
        count
        currentPage
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

const GET_B2C_TRANSACTIONS = gql`
  query(
    $filter: [filterInput]
    $first: Int!
    $page: Int
    $orderBy: [OrderByClause!]
  ) {
    transactionsByB2C(
      filter: $filter
      first: $first
      page: $page
      orderBy: $orderBy
    ) {
      data {
        id
        transaction_id
        reservation {
          id
        }
        transaction_type {
          id
          name
        }
        amount {
          id
          physical_amount
          physical_currency
        }
        payment_date
      }
      paginatorInfo {
        count
        currentPage
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

const E_TICKET_DOWNLOAD_LINK = gql`
  mutation($reservation_id: ID!) {
    eticketExport(reservation_id: $reservation_id) {
      status
      message
    }
  }
`;

export {
  GET_COMPANY,
  GET_RESERVATIONS,
  GET_TRANSACTIONS,
  GET_B2C_TRANSACTIONS,
  GET_PRODUCT_BY_RESERVATION_ID,
  GET_TRANSACTION_BY_RESERVATION_ID,
  GET_PASSENGER_BY_RESERVATION_ID,
  E_TICKET_DOWNLOAD_LINK
};
