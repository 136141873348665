import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Popover } from "@material-ui/core";
import {
  AUTH_TOKEN,
  TOKEN_TYPE,
  LOGIN_USER_ID,
  IDENTIFIER,
  EXPIRES_IN,
  LOGIN_PEOPLE_COMPANY,
  LOGIN_USER_PEOPLE,
  IS_B2B,
  IS_CALL_CENTER,
  IS_CUSTOMER,
  LOGIN_PEOPLE_DETAIL
} from "config/constants";
import { isAuthenticated } from "lib/auth";
import { clientV3 } from "../utils/v3utils";
import LoginIconWhite from "assets/images/header-menu/login_icon_white.svg";
import LoginIconBlack from "assets/images/header-menu/login_icon_black.svg";
import LoginIconBlue from "assets/images/header-menu/login_icon_blue.png";
//import LoginMobileWebIcon from "assets/images/updated-mobile-menu-logos/profile.svg";
import { LOGOUT_QUERY } from "queries/loginquery";
import { IS_EMPLOYEE } from "../config/constants";
import { checkInApp, getClientTag } from "../utils/checkurl";
import OTP_LOGIN_EMAIL_REGEX from "../config/regex/otpLoginEmailRegex";

const AgentLoginMenu = ({
  selectedLanguage,
  isShow,
  setIsShow,
  isMobile,
  isWhiteColor,
  isInApp
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [clientTag] = useState(getClientTag(window.location.search));
  const [displayUserIdentity, setDisplayUserIdentity] = useState("");

  const isCustomer =
    localStorage.getItem(IS_CUSTOMER) &&
    localStorage.getItem(IS_CUSTOMER) === "yes"
      ? true
      : false;

  const history = useHistory();
  const location = useLocation();

  const getDisplayLoginUserIdentity = () => {
    const identifier = localStorage.getItem(IDENTIFIER);

    if (OTP_LOGIN_EMAIL_REGEX.test(identifier)) {
      setDisplayUserIdentity(identifier.split("@")[0]);
      return identifier.split("@")[0];
    }
    return identifier;
  };

  /** useEffect to load display name to state from localstorage when component is first mounted */
  useEffect(() => {
    setDisplayUserIdentity(getDisplayLoginUserIdentity());
  }, []);

  const commonFunc = () => {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(TOKEN_TYPE);
    localStorage.removeItem(EXPIRES_IN);
    localStorage.removeItem(IDENTIFIER);
    localStorage.removeItem(LOGIN_USER_ID);
    localStorage.removeItem(LOGIN_PEOPLE_COMPANY);
    localStorage.removeItem(LOGIN_USER_PEOPLE);
    localStorage.removeItem(IS_B2B);
    localStorage.removeItem(IS_CALL_CENTER);
    localStorage.removeItem(IS_CUSTOMER);
    localStorage.removeItem(IS_EMPLOYEE);
    localStorage.removeItem(LOGIN_PEOPLE_DETAIL);
    if (checkInApp(location.pathname)) {
      window.location.href = `/${selectedLanguage}/in-app?client=${getClientTag(
        location.search
      )}`;
    } else {
      window.location.href = `/${selectedLanguage}`;
    }
  };

  const getAccountLogo = (isMobile, isInApp, isWhiteColor, clientTag) => {
    if (!isMobile) return LoginIconBlack;
    if (!isInApp) return LoginIconBlue;
    if (
      isWhiteColor &&
      (clientTag === "mpitesan" ||
        clientTag === "citizenspay" ||
        clientTag === "ayapay")
    )
      return LoginIconBlack;

    return LoginIconWhite;
  };

  const handleLogout = () => {
    clientV3
      .mutate({
        mutation: LOGOUT_QUERY
      })
      .then(() => {
        commonFunc();
      })
      .catch(err => {
        console.log(err);
        let msg =
          err &&
          err.graphQLErrors &&
          err.graphQLErrors.length > 0 &&
          err.graphQLErrors[0]
            ? err.graphQLErrors[0].extensions.reason
            : "";
        if (msg === "Not Authenticated") {
          commonFunc();
        }
      });
  };

  return (
    <>
      {!isAuthenticated() && (
        <span
          className="login_btn"
          id="login_btn"
          onClick={() => setIsShow(!isShow)}
        >
          <img
            src={
              isMobile &&
              (!isWhiteColor ||
                (isWhiteColor && clientTag === "citizenspay") ||
                (isWhiteColor && clientTag === "mpitesan") ||
                (isWhiteColor && clientTag === "ayapay"))
                ? LoginIconBlack
                : LoginIconBlack
            }
            alt=""
            className={isMobile ? "mobile_login_icon" : "desktop_login_icon"}
          />
          {!isMobile && <span className="login_title">Login</span>}
        </span>
      )}
      {isAuthenticated() && (
        <>
          {!isMobile && (
            <span
              aria-describedby="logout"
              style={{ color: "#000", paddingLeft: "20px" }}
              onClick={e => setAnchorEl(e.currentTarget)}
            >
              {displayUserIdentity}{" "}
              <i className="fa fa-caret-down" aria-hidden="true" />
            </span>
          )}
          {isMobile && (
            <span
              className="login_btn"
              id="login_btn"
              aria-describedby="logout"
              onClick={e => setAnchorEl(e.currentTarget)}
            >
              <img
                src={getAccountLogo(isMobile, isInApp, isWhiteColor, clientTag)}
                alt=""
                className={isMobile ? "mobile_login_icon" : ""}
              />
            </span>
          )}
          <Popover
            id="logout"
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
          >
            <p className="emailAddressLoginPopoverText">
              {displayUserIdentity}
              {/* {localStorage.getItem(IDENTIFIER)} */}
            </p>
            {!isCustomer && (
              <span
                onClick={() =>
                  history.push({
                    pathname: `/${selectedLanguage}/balance/${localStorage.getItem(
                      LOGIN_USER_ID
                    )}`,
                    search: window.location.search
                  })
                }
              >
                Balance
              </span>
            )}

            <span
              onClick={() =>
                history.push({
                  pathname: `/${selectedLanguage}/${
                    checkInApp(location.pathname) ? "in-app/" : ""
                  }booking-history/${localStorage.getItem(LOGIN_USER_ID)}`,
                  search: window.location.search
                })
              }
            >
              Booking History
            </span>

            {/* {isCustomer && (
              <span
                onClick={() =>
                  history.push({
                    pathname: `/${selectedLanguage}/${
                      checkInApp(location.pathname) ? "in-app/" : ""
                    }insider-dashboard/${localStorage.getItem(
                      LOGIN_USER_PEOPLE
                    )}`,
                    search: window.location.search
                  })
                }
              >
                Insider Dashboard
              </span>
            )} */}
            <hr className="hr_menu"></hr>
            <span onClick={handleLogout}>Logout</span>
          </Popover>
        </>
      )}
    </>
  );
};

export default AgentLoginMenu;
