import React, { useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import "react-flags-select/scss/react-flags-select.scss";
import { MY_MM } from "config/constants";
import "react-custom-flag-select/lib/react-custom-flag-select.min.css";
import MediaQuery from "react-responsive";
import EngMobileFlag from "../../assets/images/flags/en-flag-mobile.webp";
import MMMobileFlag from "../../assets/images/flags/mm-flag-mobile.webp";

const ReactFlags = props => {
  const [selectedCode, setSelectedCode] = useState(
    props.selectedLanguage === "my-mm" ? "MM" : "US"
  );

  const changeLanguage = (countryCode, pathName) => {
    let arr = pathName && pathName.pathname ? pathName.pathname.split("/") : [];
    if (arr.length > 1) {
      arr[1] = countryCode === "MM" ? MY_MM : "en";
      window.location = arr.join("/") + pathName.search;
    }
  };

  const getMobileFlagRightPosition = () => {
    if (props && props.mobileFlagRightPosition)
      return props.mobileFlagRightPosition;
    return props.isHomePage ? "53px" : "2px";
  };

  const getMobileFlageTransformPosition = () => {
    if (props && props.mobileFlagTransformPosition)
      return props.mobileFlagTransformPosition;
    return props.isHomePage ? "translateY(-50%)" : "translateY(14%)";
  };

  return (
    <>
      <div
        id={props.pageName ? props.pageName : ""}
        style={{ float: props.pageName ? "" : "right" }}
      >
        <MediaQuery minDeviceWidth={768}>
          {/* <ReactFlagsSelect
            className={props.className ? props.className : ""}
            defaultCountry={
              props.selectedLanguage === MY_MM || props.selectedLanguage === ""
                ? "MM"
                : "US"
            }
            countries={["US", "MM"]}
            customLabels={{
              US: "English",
              MM: "မြန်မာ"
            }}
            onSelect={countryCode => {
              changeLanguage(countryCode, props.pathName);
            }}
          /> */}
          <img
            style={{
              position: "absolute",
              // right: props.isHomePage ? "53px" : "2px",
              right: getMobileFlagRightPosition(),
              transform: getMobileFlageTransformPosition()
            }}
            width={23}
            height={23}
            src={selectedCode === "US" ? EngMobileFlag : MMMobileFlag}
            onClick={() => {
              setSelectedCode(prev => (prev === "US" ? "MM" : "US"));
              changeLanguage(
                selectedCode === "MM" ? "US" : "MM",
                props.pathName
              );
            }}
            alt="mm-mobile-flag"
          />
        </MediaQuery>
        <MediaQuery maxDeviceWidth={780}>
          <img
            style={{
              position: "absolute",
              // right: props.isHomePage ? "53px" : "2px",
              right: getMobileFlagRightPosition(),
              transform: getMobileFlageTransformPosition()
            }}
            width={23}
            height={23}
            src={selectedCode === "US" ? EngMobileFlag : MMMobileFlag}
            onClick={() => {
              setSelectedCode(prev => (prev === "US" ? "MM" : "US"));
              changeLanguage(
                selectedCode === "MM" ? "US" : "MM",
                props.pathName
              );
            }}
            alt="mm-mobile-flag"
          />
        </MediaQuery>
      </div>
    </>
  );
};

export default ReactFlags;
