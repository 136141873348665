import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import NewLogo from "assets/images/header-menu/flymya_mobile_logo.svg";
import ReactFlags from "../components/inputs/ReactFlags";
import FlightProgressbar from "../components/progressbar/FlightProgressbar";
import BusProgressbar from "../components/progressbar/BusProgressbar";
import MediaQuery from "react-responsive";
import { ContentfulData, getDataContentfulId } from "../utils/contentfulUtil";
import GorgiasIcon from "../components/GorgiasIcon";
import PhoneIcon from "../assets/images/phone-icon-2.svg";
import AgentLoginMenu from "./AgentLoginMenu";
import { triggerEmail } from "utils/gorgiasTicket";
import { isMobile } from "react-device-detect";
import { removeQueryStringByKey, checkInWeb, checkInApp } from "utils/checkurl";

import useResultPageB2BTopUp from "../hooks/resultPageB2BTopUpHook";
import { CC_PHONENO_LINK } from "../config/constants";

const PageHeader = ({
  pageName,
  selectedLanguage,
  pageContent,
  imageContent,
  pathName,
  isInApp,
  pageType,
  gorgiasInfo = null,
  showLogin
}) => {
  const [isShow, setIsShow] = useState(false);
  const location = useLocation();

  const { removeNextSearchParam } = useResultPageB2BTopUp({});

  useEffect(() => {
    setIsShow(showLogin);
  }, [showLogin]);

  const getFlageMobilePosition = () => {
    if (pageName === "B2BPage") return "-47px";
    if (
      pageName === "FlightSearch" ||
      pageName === "CustomerDetails" ||
      pageName === "FlightPaymentPage" ||
      pageName === "FlightConfirmation"
    )
      return "12px";
    return null;
  };
  if (
    (pageName === "HotelCustomerPage" ||
      pageName === "HotelPaymentPage" ||
      pageName === "FlightSearch") &&
    gorgiasInfo !== null
  ) {
    localStorage.setItem("gorgiasInfo", JSON.stringify(gorgiasInfo));
  }

  const getFlageMobileTransformPosition = () => {
    if (
      pageName === "FlightSearch" ||
      pageName === "CustomerDetails" ||
      pageName === "FlightPaymentPage" ||
      pageName === "FlightConfirmation"
    )
      return "translateY(-47%)";
  };

  return (
    <Grid item xs={12} className="header">
      <Grid
        container
        style={{ position: "relative" }}
        className="homeContainer"
      >
        {isMobile && pageName && pageName.includes("Hotel") ? (
          ""
        ) : (
          <Grid
            style={
              pageName && pageName.includes("B2BPage")
                ? { maxWidth: "none", marginLeft: "12px" }
                : {}
            }
            item
            sm={
              pageName && pageName.includes("Hotel")
                ? 2
                : pageName && pageName.includes("B2BPage")
                ? 1
                : 2
            }
            md={
              pageName && pageName.includes("Hotel")
                ? 2
                : pageName && pageName.includes("B2BPage")
                ? 1
                : 2
            }
            xs={
              pageName === "PrivacyPolicy" || pageName === "ResetPassword"
                ? 10
                : pageName && pageName.includes("Hotel")
                ? 4
                : 1
            }
            className={
              pageName === "PrivacyPolicy" || pageName === "ResetPassword"
                ? "headerLogoContainer privacyPolicyHeader"
                : "headerLogoContainer"
            }
          >
            <Link
              to={{
                pathname:
                  pageName && pageName.includes("Hotel")
                    ? "/" + selectedLanguage + "/hotel"
                    : "/" +
                      selectedLanguage +
                      `${
                        checkInApp(location.pathname)
                          ? "/in-app"
                          : checkInWeb(location.pathname)
                          ? "/in-web"
                          : ""
                      }`,
                // search: window.location.search
                search: removeNextSearchParam()
              }}
              className="pageHeaderLogo"
              data-contentful-id={getDataContentfulId("Blog Logo")}
            >
              <img
                src={NewLogo}
                className={
                  pageName && pageName.includes("B2BPage") ? "" : "headerLogo"
                }
                alt="Flymya.com"
              />
            </Link>
          </Grid>
        )}

        {!pageType && <div style={{ flexGrow: 1, height: "60px" }}></div>}

        {pageType &&
          pageType !== "Bus" &&
          pageName !== "PrivacyPolicy" &&
          pageName !== "ResetPassword" &&
          pageName !== "B2BPage" && (
            <FlightProgressbar
              pageContent={pageContent}
              pageName={pageName}
              selectedLanguage={selectedLanguage}
            />
          )}
        {pageType &&
          pageType === "Bus" &&
          pageName !== "PrivacyPolicy" &&
          pageName !== "ResetPassword" &&
          pageName !== "B2BPage" &&
          pageName !== "Promotions" && (
            <BusProgressbar
              pageContent={pageContent}
              pageName={pageName}
              selectedLanguage={selectedLanguage}
            />
          )}
        {(pageName === "PrivacyPolicy" ||
          pageName === "ResetPassword" ||
          pageName === "Promotions" ||
          pageName === "HotelSearchResult" ||
          pageName === "HotelDetailPage" ||
          pageName === "B2BPage" ||
          pageName === "Car") && (
          <MediaQuery minWidth={780}>
            <Grid
              item
              xs={2}
              sm={
                pageName === "PrivacyPolicy" ||
                pageName === "ResetPassword" ||
                pageName === "HotelSearchResult" ||
                pageName === "HotelDetailPage"
                  ? 7
                  : pageName === "B2BPage" || pageName === "Promotions"
                  ? 6
                  : 4
              }
              md={
                pageName === "PrivacyPolicy" ||
                pageName === "ResetPassword" ||
                pageName === "HotelSearchResult" ||
                pageName === "HotelDetailPage"
                  ? 7
                  : pageName === "B2BPage" || pageName === "Promotions"
                  ? 6
                  : 4
              }
              className={
                selectedLanguage === "my-mm"
                  ? "contactUsMenu mmContactUsMenu"
                  : "contactUsMenu"
              }
            >
              <Link
                id="contactUsBus"
                to={{
                  pathname: isInApp
                    ? "/" +
                      (selectedLanguage ? selectedLanguage : "en") +
                      "/in-app/promotion"
                    : "/" +
                      (selectedLanguage ? selectedLanguage : "en") +
                      "/promotion",
                  search: window.location.search
                }}
              >
                <ContentfulData content={pageContent} id="promotion">
                  Promos
                </ContentfulData>
              </Link>
              <Link
                id="contactUsBus"
                to={{
                  pathname: isInApp
                    ? "/" +
                      (selectedLanguage ? selectedLanguage : "en") +
                      "/in-app/car-rental"
                    : "/" +
                      (selectedLanguage ? selectedLanguage : "en") +
                      "/car-rental",
                  search: window.location.search
                }}
              >
                <ContentfulData content={pageContent} id="carRental">
                  Cars
                </ContentfulData>
              </Link>
              {/* <Link
                id="contactUsBalloon"
                to={{
                  pathname: isInApp
                    ? "/" +
                      (selectedLanguage ? selectedLanguage : "en") +
                      "/in-app/balloons"
                    : "/" +
                      (selectedLanguage ? selectedLanguage : "en") +
                      "/balloons",
                  search: window.location.search
                }}
                data-contentful-id={getDataContentfulId("balloon")}
              >
                <ContentfulData content={pageContent} id="balloon">
                  Balloons
                </ContentfulData>
              </Link> */}
              <Link
                id="contactUsHotel"
                to={{
                  pathname: isInApp
                    ? "/" +
                      (selectedLanguage ? selectedLanguage : "en") +
                      "/in-app/hotel"
                    : "/" +
                      (selectedLanguage ? selectedLanguage : "en") +
                      "/hotel",
                  search: window.location.search
                }}
                data-contentful-id={getDataContentfulId("hotel")}
              >
                <ContentfulData content={pageContent} id="hotel">
                  Hotels
                </ContentfulData>
              </Link>
              <Link
                id="contactUsBus"
                to={{
                  pathname: isInApp
                    ? "/" +
                      (selectedLanguage ? selectedLanguage : "en") +
                      "/in-app/bus"
                    : "/" +
                      (selectedLanguage ? selectedLanguage : "en") +
                      "/bus",
                  search: window.location.search
                }}
                data-contentful-id={getDataContentfulId("bus")}
              >
                <ContentfulData content={pageContent} id="bus">
                  Buses
                </ContentfulData>
              </Link>
              <Link
                id="contactUsFlight"
                to={{
                  pathname: isInApp
                    ? "/" +
                      (selectedLanguage ? selectedLanguage : "en") +
                      "/in-app"
                    : "/" + (selectedLanguage ? selectedLanguage : "en"),
                  search: window.location.search
                }}
                data-contentful-id={getDataContentfulId("flight")}
              >
                <ContentfulData content={pageContent} id="flight">
                  Flights
                </ContentfulData>
              </Link>
            </Grid>
          </MediaQuery>
        )}
        {pageName === "Promotions" && (
          <MediaQuery maxWidth={780}>
            <Grid
              item
              xs={10}
              sm={
                pageName === "PrivacyPolicy" ||
                pageName === "ResetPassword" ||
                pageName === "HotelSearchResult" ||
                pageName === "HotelDetailPage"
                  ? 7
                  : pageName === "B2BPage" || pageName === "Promotions"
                  ? 7
                  : 4
              }
              md={
                pageName === "PrivacyPolicy" ||
                pageName === "ResetPassword" ||
                pageName === "HotelSearchResult" ||
                pageName === "HotelDetailPage"
                  ? 7
                  : pageName === "B2BPage" || pageName === "Promotions"
                  ? 7
                  : 4
              }
              className={"contactUsMenu mobContactUsMenu"}
            >
              <Link
                id="contactUsBus"
                to={{
                  pathname: isInApp
                    ? "/" +
                      (selectedLanguage ? selectedLanguage : "en") +
                      "/in-app"
                    : "/" + (selectedLanguage ? selectedLanguage : "en"),
                  search: window.location.search
                }}
              >
                Flights
              </Link>
              <Link
                id="contactUsBalloon"
                to={{
                  pathname: isInApp
                    ? "/" +
                      (selectedLanguage ? selectedLanguage : "en") +
                      "/in-app/bus"
                    : "/" +
                      (selectedLanguage ? selectedLanguage : "en") +
                      "/bus",
                  search: window.location.search
                }}
                data-contentful-id={getDataContentfulId("balloon")}
              >
                <ContentfulData content={pageContent} id="balloon">
                  Bus
                </ContentfulData>
              </Link>
              <Link
                id="contactUsHotel"
                to={{
                  pathname: isInApp
                    ? "/" +
                      (selectedLanguage ? selectedLanguage : "en") +
                      "/in-app/hotel"
                    : "/" +
                      (selectedLanguage ? selectedLanguage : "en") +
                      "/hotel",
                  search: window.location.search
                }}
                data-contentful-id={getDataContentfulId("hotel")}
              >
                <ContentfulData content={pageContent} id="hotel">
                  Hotels
                </ContentfulData>
              </Link>
              {!isInApp && (
                <Link
                  id="contactUsBus"
                  to={{
                    pathname: isInApp
                      ? "/" +
                        (selectedLanguage ? selectedLanguage : "en") +
                        "/in-app/car-rental"
                      : "/" +
                        (selectedLanguage ? selectedLanguage : "en") +
                        "/car-rental",
                    search: window.location.search
                  }}
                >
                  <ContentfulData content={pageContent} id="carRental">
                    Cars
                  </ContentfulData>
                </Link>
              )}
            </Grid>
          </MediaQuery>
        )}
        {(pageName && pageName.includes("Hotel")) ||
          (pageName && pageName.includes("FlightSearch")) ||
          (pageName && pageName.includes("CustomerDetails")) ||
          (pageName && pageName.includes("B2BPage") && (
            <MediaQuery maxWidth={780}>
              <div
                style={
                  pageName.includes("B2BPage")
                    ? {
                        display: "flex",
                        alignItems: "center",
                        marginRight: "12px"
                      }
                    : pageName.includes("Flight") ||
                      pageName === "CustomerDetails"
                    ? {
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginRight: "18px"
                      }
                    : {
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginRight: "18px"
                      }
                }
              >
                <a
                  style={{
                    position: "absolute",
                    right: "35%",
                    transform: "translateY(-12%)"
                  }}
                  href={CC_PHONENO_LINK}
                >
                  <div
                    onClick={() => {
                      if (pageName.includes("B2BPage")) return;
                      let product = pageName.includes("Hotel")
                        ? "Hotel"
                        : "Flight";
                      triggerEmail(product, gorgiasInfo, isInApp);
                    }}
                  >
                    <img
                      className="menuPhone"
                      src={PhoneIcon}
                      alt=""
                      style={{
                        width: "20px",
                        height: "20px"
                      }}
                    />
                  </div>
                </a>
              </div>
              {pageName && !pageName.includes("B2BPage") && <GorgiasIcon />}
            </MediaQuery>
          ))}

        {pageName === "B2BPage" && (
          <Grid
            item
            xs={1}
            sm={3}
            // sm={2}
            className="login-company"
          >
            <MediaQuery minWidth={780}>
              <AgentLoginMenu
                selectedLanguage={selectedLanguage}
                setIsShow={setIsShow}
                isShow={isShow}
              />
            </MediaQuery>
            <MediaQuery maxWidth={780}>
              <div style={{ right: "21%", position: "absolute" }}>
                <AgentLoginMenu
                  selectedLanguage={selectedLanguage}
                  isMobile={true}
                  setIsShow={setIsShow}
                  isShow={isShow}
                />
              </div>
            </MediaQuery>
          </Grid>
        )}

        {pageName !== "ResetPassword" && (
          <Grid
            item
            style={{ padding: "10px" }}
            xs={pageName === "B2BPage" ? 1 : 1}
            sm={1}
            className={
              pageName === "B2BPage"
                ? "language-select-login"
                : "language-select"
            }
          >
            <ReactFlags
              className="flightResult"
              mobileFlagRightPosition={getFlageMobilePosition()}
              mobileFlagTransformPosition={getFlageMobileTransformPosition()}
              selectedLanguage={selectedLanguage}
              pathName={pathName}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default PageHeader;
