import React from "react";

export const getQueryParameter = param => {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] === param) {
      return pair[1];
    }
  }
  return false;
};

export const isContentful = () => getQueryParameter("contentful") === "true";

export const toSnakeCase = s =>
  s
    .replace(/(?:^|\.?)([A-Z])/g, (x, y) => {
      return "_" + y.toLowerCase();
    })
    .replace(/^_/, "");

export const getDataContentfulId = id =>
  isContentful() && id ? id : undefined;

export const ContentfulData = ({ content, id, children, parser = null }) => {
  return content && content[id]
    ? parser
      ? parser(content[id])
      : content[id]
    : children;
};

export const ContentfulImg = ({ content, id, alt, className }) => {
  const contenfulId = getDataContentfulId(id);

  const img = (
    <img
      className={`ContentfulID-${id} ${className ? className : ""}`.trim()}
      src={content && content[id] && content[id].url}
      data-contentful-id={contenfulId}
      alt={alt}
    />
  );

  return isContentful() ? (
    <span style={{ display: "inline-flex" }} data-contentful-id={contenfulId}>
      {img}
    </span>
  ) : (
    img
  );
};
